import React  from 'react';

export const TextSide = 
{
    LEFT:           0,
    RIGHT:          1
}

const SimpleImageButtonWithText = ({image,text,side=TextSide.RIGHT,onClick,addClass="p-2 h5 text-secondary"}) =>
{
    const iconStyle = {
        cursor: 'pointer'
      };

      const fimage = () =>
      {
         return 'bi bi-'+ image
      }

      const executeOnClick = (e) =>
      {
        e.preventDefault();
        onClick()
      }

      if(side === TextSide.RIGHT)
      {
        return (
          <span onClick={executeOnClick} className={addClass}  style = {iconStyle}>
          <i className={fimage()} /> {text} 
          </span>
        )
      }
      else
      {
        return (
          <span onClick={executeOnClick} className={addClass}  style = {iconStyle}>
          {text} <i className={fimage()} /> 
          </span>
        )
      }
}

export default SimpleImageButtonWithText