import React  from 'react';
import { Container, Spinner } from 'react-bootstrap';

const Loading = () => 
{
    return (
            <Container>        
                <div className="text-center">
                    <div className='m-3'>Cargando...</div>
                    <Spinner animation="border" size="sm" />
                    <Spinner animation="border" />
                    <Spinner animation="grow" size="sm" />
                    <Spinner animation="grow" />
                </div>
            </Container>     
            )
}

export default Loading