import React, {Fragment} from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/auth";
import NavBar from "../components/NavBar";
import Error from "../components/Error";

const Main = ( {authUser,children, allow, logout} ) => {

    if (!authUser.isAuthenticated)
      return <Navigate to='/' replace={true} />

    const getChildren = () =>
    {        
        if(allow !== undefined && allow !== null && allow.length !== 0)
        {
            if (!authUser.isReviewer && allow.indexOf('reviewer') !== -1)
            {
                return <Error>Contenido no permitido para su usuario</Error>
            }
            if(!authUser.isAdmin && allow.indexOf('admin') !== -1)
            {
                return <Error>Contenido no permitido para su usuario</Error>
            }
        }
        return children
    }

    return(  
        <Fragment>
            <NavBar authUser={authUser} logout={logout}/>
            {getChildren()}
        </Fragment>
    )

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth
    }}; 

export default connect(mapStateToProps, {logout} )(Main)