import React, {useState} from "react";
import { Modal,Button,Form,FormGroup,Accordion } from 'react-bootstrap';
import { createStaff } from "../actions/setters";
import { ReqStatus } from "../actions/types";
import { DialogCloseOptions } from "../actions/types"; 

import RequestResult from "../components/RequestResult";
import CSRFToken from '../components/CSRFToken';

const StaffCreateDialog = ({show,handleClose}) =>
{
    const emptyDta = {email: '',
                      first_name: '',
                      last_names: '',
                      is_admin:false,
                      is_reviewer:false,
                      is_sender:false,
                      password:''
                     }

    const [formData,setFormData] = useState(emptyDta);
    const [result,setResultV]    = useState(null);
    
    const onChange = e => {
        setFormData( {...formData,[e.target.name]:e.target.value.trim()} )
    }

    const onChangePassword = e => {
        formData.password = e.target.value
        setFormData( formData )
    }

    const onChangeEmail = e => {
        formData.email = e.target.value
        setFormData( formData )
    }

    const onChangeCheckbox = e => {        
        setFormData( {...formData,[e.target.name]:e.target.checked} )
    }

    const setResult = (result) =>
    {
        if(result.code === ReqStatus.CREATED)
        {
            setFormData(emptyDta)
            setResultV(null)
            handleClose(DialogCloseOptions.NEW_DATA,result.data)            
        }
        else
        {
            setResultV(result.code)           
        }
    }

    const onSubmit = e =>{
        e.preventDefault();
        const fecthData = async() => {    
            try
            { 
              const res = await createStaff(formData)            
              setResult(res)              
            }
            catch
            {
                setResult(ReqStatus.ERROR)
            }
          }
          fecthData();                    
    }

    const onHide = () =>
    {
        setFormData(emptyDta)
        setResultV(null)
        handleClose(DialogCloseOptions.ONLY_CLOSE,null)
    }

    const closeNotification = () =>
    {
        setResultV(null)
    }

return (
    <Modal 
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}>
    <Form onSubmit={e=>onSubmit(e)}>
        <CSRFToken />

        <Modal.Header closeButton>
            <Modal.Title>Dar de alta personal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                    
            <FormGroup className="mb-4">
                <Form.Label>Email:</Form.Label>
                <Form.Control 
                            required 
                            name="new-email" 
                            onChange={ e=>onChangeEmail(e) } 
                            type="email" 
                            autoComplete="new-email"
                            placeholder="Email"
                            defaultValue="" />
            </FormGroup>
            <hr className="hr"/>  
            <FormGroup className="mb-4">
                <Form.Label className="mt-1">Nombre:</Form.Label>
                <Form.Control 
                            required 
                            name="first_name" 
                            onChange={ e=>onChange(e) } 
                            type="text" 
                            placeholder="Nombre"
                            defaultValue="" /> 

                <Form.Label className="mt-1">Apellidos:</Form.Label>
                <Form.Control 
                            name="last_names" 
                            onChange={ e=>onChange(e) } 
                            type="text" 
                            placeholder="Apellidos"
                            defaultValue="" />                                                        
            </FormGroup>
            <hr className="hr"/>
            <FormGroup className="mb-4">

                <Form.Check
                    label= "Permiso de gestión plataforma"
                    name="is_admin"
                    onChange={ e=>onChangeCheckbox(e) } 
                />

                <Form.Check
                    label= "Permiso de revisión"
                    name="is_reviewer"          
                    onChange={ e=>onChangeCheckbox(e) }           
                />

                <Form.Check
                    label="Permiso de uso dispositivo de captura"
                    name="is_sender"
                    onChange={ e=>onChangeCheckbox(e) } 
                />
                
            </FormGroup>

            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Añadir contraseña manualmente</Accordion.Header>
                     <Accordion.Body>
                     <Form.Control 
                            name="new-password" 
                            onChange={ e=>onChangePassword(e) } 
                            autoComplete="new-password"
                            type="password" 
                            placeholder="contraseña"
                            defaultValue="" />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <RequestResult onClose={closeNotification} result={result}></RequestResult>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="primary" type="submit">Crear</Button>
        </Modal.Footer>

    </Form>    
    </Modal>
)

}

export default StaffCreateDialog
