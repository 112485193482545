import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
} from '../actions/types'

const initialState = {
    id: null,
    isAuthenticated: false,
    isAdmin:false,
    isReviewer:false,
    email:'',
    first_name:'',
    last_names:'',
    group: ''
}


const act = function(state = initialState,action){
    const {type,payload} = action;

    switch(type) {
        case LOGIN_SUCCESS:
            return {...state,                    
                    isAuthenticated:true,
                    id:payload.id,
                    email:payload.email,
                    first_name:payload.first_name,
                    last_names:payload.last_names,
                    isAdmin:payload.is_admin,
                    isReviewer:payload.is_reviewer,
                    group:payload.group
                   }
        case LOGOUT_SUCCESS:
                return {
                    ...state,
                    isAuthenticated: false,
                    isAdmin:false,
                    isReviewer:false,                
                    email:'',
                    first_name:'',
                    last_names:'',
                    group: ''
                }            
        case LOGIN_FAIL:
        case LOGOUT_FAIL:
            return state            
        default:
            return state
    }
}

export default act