import React  from 'react';
import { Container, Alert } from 'react-bootstrap';

const Error = ({children}) => 
{
    return (
            <Container className='mt-5'>        
                <Alert variant={"danger"} className="text-center">
                    <h4>{children}</h4>
                </Alert>
            </Container>     
            )
}

export default Error