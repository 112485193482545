import { ReqStatus } from "./types";
import moment from 'moment';
import axios from "axios";
import Cookies from "js-cookie";

axios.defaults.timeout = 5000; // Set default timeout to 5 seconds

export const codeToStatus = (status) =>
{
    if (status === 200)
    {
        return ReqStatus.OK
    }
    else if(status === 201)
    {
        return ReqStatus.CREATED
    }
    else if(status === 206)
    {
        return ReqStatus.PARTIAL_CONTENT
    }
    else if(status === 400)
    {
        return ReqStatus.BAD_REQUEST
    }
    else if(status === 401)
    {
        return ReqStatus.UNAUTHORIZED
    }
    else if(status === 403)
    {
        return ReqStatus.FORBIDDEN
    }
    else if(status === 404)
    {
        return ReqStatus.NOT_FOUND
    }   
    else if(status === 408)
    {
        return ReqStatus.TIMEOUT
    }    
    else if(status === 409)
    {
        return ReqStatus.ALREADY_EXISTS
    }    
    else if(status === 500)
    {
        return ReqStatus.SERVER_ERROR
    }    
    else
    {
        return ReqStatus.ERROR
    }
}

export const getDate = (date) => {
    return moment(date).format("DD/MM/YYYY")    
  }

export const Action = {
    POST   : 0,
    DELETE : 1,
    PUT    : 2
}


const processAxioError = (error) =>
{

    if(error === null)
    {
        return { code: ReqStatus.ERROR,
                 data: null }    
    }

    if (error.response) 
    {
        return { code: codeToStatus(error.response.status),
                 data: error.response.data }
    }
    else if (error.code === "ECONNABORTED")
    {
        return { code: ReqStatus.TIMEOUT,
                 data: null }
    }
    else if (error.request) 
    {
        return { code: ReqStatus.TIMEOUT,
                 data: null }
    } 
    else 
    {
        return { code: ReqStatus.ERROR,
                 data: null }
    }
}

const processAxioResponse = (response) =>
{
    if(response === null)
    {
        return { code: ReqStatus.ERROR,
                 data: null }    
    }

    const status = codeToStatus(response.status)        
    return { code: status,
             data: response.data }
}


export const requestToServer = async function(endpoint,action,body = null)
{
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/${endpoint}`

    const config = {
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',      
            'X-CSRFToken': Cookies.get('csrftoken')      
        }
    }
    try
    {
        let res = null
        if (action === Action.DELETE)
        {
            res = await axios.delete(url,config)
        }
        else if (action === Action.POST)
        {
            res = await axios.post(url,body,config)
        }
        else if (action === Action.PUT)
        {
            res = await axios.put(url,body,config)
        }        
        return processAxioResponse(res)
    }
    catch(error)
    {
        return processAxioError(error)     
    }
 
}
