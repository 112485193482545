import React from "react";
import { redirect, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styles from "./NavBar.module.css"
import { useQuery } from "@tanstack/react-query";
import { getMyResidencias } from "../actions/getters";
import Loading from "./Loading";

const NavBar = ( {authUser,logout} ) => {    
    
  const navigate = useNavigate();


  const { isLoading, isError, data, error } = useQuery({ queryKey: ['myresidencia'],
                                                        queryFn: () => getMyResidencias()})


    const eventLogOut = function(e) {
        e.preventDefault();    
        logout();
      }
      
    const residencias = function(e) {
        e.preventDefault();    
        navigate("/residencias");
      }

    const personal = function(e) {
        e.preventDefault();    
        navigate("/personal");
      }

    const sesiones = function(e) {
        e.preventDefault();    
        navigate("/sesiones");
      }

    const inicio = function(e) {
        e.preventDefault();    
        navigate("/inicio");
      }

      const profile = function(e) {
        e.preventDefault();    
        navigate("/perfil");
      }

      const toSessionResidencia = function(e,id) {
        e.preventDefault();    
        navigate(`/medidas/${id}/`);
      }

      const printResidencias = () => 
      {
        if (isLoading)
        {
          return <div><Loading/></div>
        }
        else if (isError)
        {
          return <div>Error obteniendo residencias</div>
        }
        else
        {
          return data.map(residencia => (
                            <NavDropdown.Item key={residencia.id} onClick={ e => toSessionResidencia(e,residencia.id)}>
                              {residencia.name}
                            </NavDropdown.Item>))
        }
      }
      

    return(    
        <Navbar expand="lg"  className="w-auto bg-body-tertiary shadow">
          <Container fluid>
          <Navbar.Brand style={{cursor:'pointer'}} onClick={inicio}>RGB Care - {authUser.group} - </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">               
              {authUser.isAdmin &&                   
                <NavDropdown title="Administración" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={residencias}>Residencias</NavDropdown.Item>
                  <NavDropdown.Item onClick={personal}>Personal</NavDropdown.Item>
                </NavDropdown>
              }
              {authUser.isReviewer &&
                <NavDropdown title="Medidas" id="basic-nav-dropdown2">
                  {printResidencias()}
                </NavDropdown>
              }
            </Nav>

            <Nav className="ms-auto">
              <Nav.Link onClick={profile}> <i className="bi bi-person-circle"></i> Perfil</Nav.Link>
              <Nav.Link onClick={eventLogOut}> <i className="bi bi-box-arrow-right"></i> Salir</Nav.Link>
            </Nav>
            
        </Navbar.Collapse>
        </Container>
      </Navbar>
        )

}

export default NavBar
