import { requestToServer,Action } from "./tools";

export const createResidencia = async function(name)
{
    const vct = [{name}]
    return requestToServer(`adm/residencia/`,Action.POST,JSON.stringify(vct))
};

export const updateResidencia = async function(id_residencia,data)
{
    return requestToServer(`adm/residencia/${id_residencia}/`,Action.PUT,JSON.stringify(data))
};

export const deleteResidencia = async function(id_residencia)
{
    return requestToServer(`adm/residencia/${id_residencia}/`,Action.DELETE)
};

export const createPatient = async function(id_residencia,data)
{
    let vct = []
    if (Array.isArray(data))
    {
        vct = data
    }
    else
    {
        vct.push(data)
    }
    return requestToServer(`adm/residencia/${id_residencia}/patient/`,Action.POST,JSON.stringify(vct))
};

export const updatePatient = async function(id_patient,data)
{
    return requestToServer(`adm/patient/${id_patient}/`,Action.PUT,JSON.stringify(data))
};

export const deletePatient = async function(id_patient)
{
    return requestToServer(`adm/patient/${id_patient}/`,Action.DELETE)
};

export const createStaff = async function(data)
{
    let vct = []
    if (Array.isArray(data))
    {
        vct = data
    }
    else
    {
        vct.push(data)
    }
    return requestToServer(`staff/`,Action.POST,JSON.stringify(vct))
};

export const updateStaff = async function(id_staff,data)
{
    return requestToServer(`staff/${id_staff}/`,Action.PUT,JSON.stringify(data))
};

export const deleteStaff = async function(id_staff)
{
    return requestToServer(`staff/${id_staff}/`,Action.DELETE)
};

export const deleteStaffFromResidence = async function(id_staff,id_residencia)
{
    return requestToServer(`staff/${id_staff}/residencia/${id_residencia}/`,Action.DELETE)
};

export const addStaffFromResidence = async function(id_staff,id_residencia)
{
    return requestToServer(`staff/${id_staff}/residencia/${id_residencia}/`,Action.POST)
};

export const addResidenceToStaff_many = async function(id_staff,residencias)
{
    return requestToServer(`staff/${id_staff}/residencia/`,Action.POST,JSON.stringify(residencias))
};

export const addStaffToResidence_many = async function(id_residencia,staff)
{
    return requestToServer(`residencia/${id_residencia}/staff/`,Action.POST,JSON.stringify(staff))
};
