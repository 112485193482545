import React from 'react';
import moment from 'moment';
import "bootstrap-icons/font/bootstrap-icons.css";
import ComplexValueField from './ComplexValueField';
import ViewGraphButton from './ViewGraphButton';
import Operator from './Operator';

const SpO2Field = ( {value} ) =>
{
  const getTime = (str) => {
    return moment(str).format("HH:mm:ss")
    }

  return(<tr>
          <td className="patientcol1">#</td>
          <td className="patientcol2">{ getTime(value.date_time) }</td>
          <td className="patientcol3">SpO<sub>2</sub></td>
          <td>     
            <ComplexValueField name={"Saturación"} value={value.simplified_data.sat} units={"%"}></ComplexValueField>             
          </td>
          <td>
            <ComplexValueField name={"Pulso"} value={value.simplified_data.pulse} units={"ppm"}></ComplexValueField> 
          </td>
          <td className="patientcolHist">
              <ViewGraphButton id={value.id}></ViewGraphButton>
          </td>          
          <td className="patientcolOp">
            <Operator operator={value.user}></Operator>
          </td>

         </tr>)
}

export default SpO2Field