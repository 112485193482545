import React ,{useState,useEffect}  from 'react';

import {    LineChart,
            Line,
            XAxis,
            YAxis,
            CartesianGrid,
            Label,
            Legend,
            Brush,
            Tooltip,
            ReferenceArea,
            ResponsiveContainer
        } from 'recharts';  

import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import moment from 'moment';        
import Operator from './Operator';

const ECGGraph = ({curve_data,operator}) =>
{

    const graph_color       = "#1aab40"
    const point_size        = 5
    const brush_gap         = 20
    const max_number_x_gaps = 35

    const scales = [
        { name: '1 mV' , value: '1'  },
        { name: '2 mV' , value: '2'  },
        { name: '4 mV' , value: '4'  },
        { name: '8 mV' , value: '8'  },        
        { name: '16 mV', value: '16' },        
        { name: '32 mV', value: '32' }
      ];
    
    const [datasets, setDataSets] = useState(null)
    const [interval, setInterval] = useState(19);
    const [window,   setWindow]   = useState({startIdx:0,endIdx:600});

    const createDataSets = (dta) => {

        if (dta === undefined || dta === null || Object.keys(dta).length === 0 ) return null
        
        let data_ecg   = []
        let data_pulse = []        

        let max_x = 0;

        let min_y = 32767;
        let max_y = 0;

        const initial_tick = getInitialTickWidth(dta.curve1.length)
        let intial_range = [32767,0]

        let x = 0
        for (let i = 0; i < dta.curve1.length; i ++)
        {
            if( dta.curve1[i] > 0 )
            {
                data_ecg.push( {x: x,
                                y: dta.curve1[i]} );

                if (dta.curve1[i] < min_y)
                {
                    min_y = dta.curve1[i]
                }
                if (dta.curve1[i] > max_y)
                {
                    max_y = dta.curve1[i]
                }

                if(i <= initial_tick)
                {
                    if (dta.curve1[i] < intial_range[0])
                    {
                        intial_range[0] = dta.curve1[i]
                    }
                    if (dta.curve1[i] > intial_range[1])
                    {
                        intial_range[1] = dta.curve1[i]
                    }        
                }

            }
            else
            {
                data_ecg.push( {x: x,
                                y: null} );
            }
                                        
            x = x + 5            
        }

        max_x = x;

        let min_freq_y = 32767;
        let max_freq_y = 0;

        for (let i = 0; i < dta.freq.length; i ++)
        {
            if(dta.freq[i][1] > 0)
            {
                data_pulse.push( {x: dta.freq[i][1] ,
                                  y: dta.freq[i][0] } );

                if (dta.freq[i][0] < min_freq_y)
                {
                    min_freq_y = dta.freq[i][0]
                }
                if (dta.freq[i][0] > max_freq_y)
                {
                    max_freq_y = dta.freq[i][0]
                }
                    
                if(i === dta.freq.length - 1 && dta.freq[i][1]  > max_x)
                {
                    max_x = dta.freq[i][1]
                }
            }                            
        }

        let domain_freq_min_y = 40
        let domain_freq_max_y = 120

        if(max_freq_y > domain_freq_max_y)
        {
            const aux  = max_freq_y - domain_freq_max_y
            const mult = Math.ceil(aux / 20.0)
            domain_freq_max_y = 20*mult + domain_freq_max_y
        }

        if(min_freq_y < domain_freq_min_y)
        {
            const aux  = domain_freq_min_y - min_freq_y
            const mult = Math.ceil(aux / 20.0)
            domain_freq_min_y = domain_freq_min_y - 20*mult
            if(domain_freq_min_y < 0)
            {
                domain_freq_min_y = 0
            }
        }

        let mv = 1;

        const div = max_y - min_y
        if (div <= 1000)                     mv = 1
        else if (div <= 2000 && div > 1000)  mv = 2
        else if (div <= 4000 && div > 2000)  mv = 4
        else if (div <= 8000 && div > 4000)  mv = 8
        else if (div <= 16000 && div > 8000) mv = 16
        else mv = 32
        
        if(mv >= 16 )
        {
           mv = 4           
        }

        const range_y = calculateRangeY(intial_range,mv)

        const range_x      =  [ 0 , Math.ceil(max_x / 1000.0)*1000 ]
        const range_freq_y =  [ domain_freq_min_y , domain_freq_max_y ]

        const data = { ecg:   data_ecg,
                       pulse: data_pulse,
                       max_range_y:[min_y,max_y],
                       range_x : range_x,
                       range_y : range_y,
                       range_freq_y: range_freq_y,
                       duration: max_x,
                       mv: mv }
        return data;
    }

    useEffect(() => {
      const setData = async() => {    
          const ds = createDataSets(curve_data)
          setWindow({startIdx:0,endIdx:getInitialTickWidth(ds.ecg.length)})
          setDataSets(ds)            
      }
      setData()        
    },[curve_data])

    const calculateRangeY = (minMaxRange,mv) =>
    {
        const div = minMaxRange[1] -  minMaxRange[0];

        if (div > mv*1000)
        {
            const bs = minMaxRange[0] + Math.round(div/2.0);
            return [ bs - (mv*1000/2.0) , bs + (mv*1000/2.0) ]
        }
        else
        {
            const pos = Math.round((mv*1000 - div)/2)
            const range_y   =  [ (minMaxRange[0] - pos) , (minMaxRange[0] - pos)+ mv*1000 ]
            return range_y;
        }
    }

    const tickVFormatter = (value,tick) => 
    {
        return ""
    }

    const tickBFormatter = (value,tick) => 
    {
        return (value*5/1000).toString() + "s"
    }

    const getSessionDuration = () =>
    {
        return Math.round( datasets.duration/1000.0 )
    }

    const getVerticalTicks = () =>
    {
        const tck = [...Array( (datasets.mv + 1) ).keys() ].map((i) => datasets.range_y[0] + i * 1000)        
        return tck;        
    }

    const getInitialTickWidth = (length) =>
    {
        if (length > 600)
        {
            return 600
        }
        else
        {
            return length - 1
        }
    }
    
    const getIntervalFrom_ms = (ms) =>
    {
        return Math.round(ms / 5.0) - 1
    }

    const calculateInterval_ms = (shown_ms) => 
    {
        if(shown_ms <= max_number_x_gaps*100)
        {
             return 100;
        }
        else
        {
            return Math.ceil( (shown_ms / max_number_x_gaps) / 100.0) * 100
        }               
    }

    const labelFormatter = (value, name) => 
    {
        return ""
    }

    const tickFormatter = (value,tick) => 
    {
        return (value/1000)
    }


    const changeBrush = (a) =>
    {
        const ms     = (a.endIndex - a.startIndex)*5
        const ms_int = calculateInterval_ms(ms)
        const intr   = getIntervalFrom_ms(ms_int)
        setWindow({startIdx:a.startIndex,endIdx:a.endIndex})
        if(intr !== interval)
        {
            setInterval(intr)
        }
    }

    const changeScaleIf = (checked,mv) =>
    {
        if(checked)
        {
            changeScale(mv)  
        }
    }

    const changeScale = (mv) =>
    {
        if(curve_data.curve1.length > 0 )
        {
            const x0 = window.startIdx
            const x1 = window.endIdx

            let minMaxY = [40000,0]

            for (let i = x0; i <= x1; ++i)
            {
                if(i < curve_data.curve1.length && curve_data.curve1[i] >= 0)
                {
                    if (curve_data.curve1[i] < minMaxY[0])
                    {
                        minMaxY[0] = curve_data.curve1[i]
                    }
                    if (curve_data.curve1[i] > minMaxY[1])
                    {
                        minMaxY[1] = curve_data.curve1[i]
                    }
                }    
            }
            const range_y = calculateRangeY(minMaxY,mv)
            setDataSets({...datasets,"range_y":range_y,"mv":mv})            
        }
    }

    if ( datasets === null )
    {
        return <h4>No hay gráficos disponibles de ECG</h4>
    }
    
    return (

        <div style={{ width: '100%' }}>

            <div className='text-center'>

                <h4 ><span className='me-2'>Sesión ECG</span><Operator operator={operator}></Operator></h4>
            <div>
                <span className='text-muted'>Fecha y hora:</span> {moment(curve_data.curve_ts).format("DD/MM/YYYY HH:mm:ss")}                                            
                <span style={ {marginLeft:5 + "em"} } className='text-muted'>Duración:</span> {getSessionDuration()} seg. 
            </div>          
            </div>

        { datasets.pulse.length > 0 &&

            <ResponsiveContainer width="100%" height={200}>
                <LineChart
                    width={500}
                    height={150}
                    data={datasets.pulse}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        xAxisId="x_freq"                  
                        dataKey="x"                
                        style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}              
                        type="number"
                        tickFormatter={tickFormatter}
                        domain={[0,datasets.duration]}  
                    >
                        <Label
                            position='insideBottom'                
                            value= {"segundos"}
                            style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}
                        />
                    </XAxis>

                    <YAxis  domain={datasets.range_freq_y}  />
                    
                    <Legend  verticalAlign="top" height={36}/>
                    <Tooltip labelFormatter={labelFormatter}/>

                    <ReferenceArea xAxisId="x_freq" x1={window.startIdx*5} x2={window.endIdx*5} y1={datasets.range_freq_y[0]} y2={datasets.range_freq_y[1]} strokeOpacity={0.1} />

                    <Line 
                        xAxisId="x_freq" 
                        name="Ritmo cardiaco"  
                        isAnimationActive={false} 
                        type="stepAfter" 
                        dot={{strokeWidth: point_size}} 
                        unit="ppm"
                        dataKey="y" 
                        strokeWidth={0} 
                        stroke={graph_color} 
                        fill={graph_color}/>
                </LineChart>
            </ResponsiveContainer>

        }
        <div className='mb-2'>
        <ResponsiveContainer width="100%" height={400}>
            <div className='ms-5 mt-3'>
                <ButtonGroup>
                {scales.map((scale) => (            
                <ToggleButton
                    key={scale.value}
                    id={`scale-${scale.value}`}
                    type="radio"
                    variant= "outline-success"
                    name="scale"
                    value={scale.value}
                    checked={parseInt(scale.value) === datasets.mv}                
                    onChange={(e) => changeScale(parseInt(e.currentTarget.value))}
                    onClick={ (e) => changeScaleIf( parseInt(scale.value) === datasets.mv ,scale.value) }
                >
                    {scale.name}
                </ToggleButton>
                ))}
            </ButtonGroup>
            </div>
            <LineChart
                width={500}
                height={350}
                data={datasets.ecg}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 15,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    xAxisId="x_ecg"                  
                    dataKey="x"
                    interval={interval}
                    tickFormatter={tickFormatter}
                    style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}                
                >
                    <Label
                        position='insideBottom'                
                        value= {"segundos"}
                        style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}
                    />
                </XAxis>

                <YAxis 
                    tickFormatter={tickVFormatter} 
                    ticks={getVerticalTicks()}
                    axisLine={true} 
                    tickLine={false}
                    dataKey="y"
                    type="number" 
                    allowDataOverflow={true}
                    domain={datasets.range_y}>
                    <Label
                        position='insideTopLeft'
                        dx = {10}
                        dy = {-10}
                        value= {datasets.mv.toString() + " mV"}
                        style={{ fontSize: '100%', fill: 'gray', padding: '100px' }}
                    />
                </YAxis>          
                <Legend verticalAlign="top" height={36}/>

                <Brush 
                    tickFormatter={tickBFormatter}
                    gap={brush_gap}
                    startIndex={window.startIdx} 
                    endIndex={window.endIdx}
                    onChange={(a)=>{changeBrush(a)}} />
                <Line 
                    xAxisId="x_ecg" 
                    name="Curva ECG Der. I"  
                    isAnimationActive={false} 
                    type="monotone" 
                    dot={false} 
                    dataKey="y" 
                    strokeWidth={2} 
                    stroke={graph_color}
                    fill  ={graph_color}/>
            </LineChart>
        </ResponsiveContainer>
        </div>
    </div>

    )

}

export default ECGGraph
