import React, {useState} from "react";
import {Link, Navigate} from 'react-router-dom';
import { connect } from "react-redux";
import {login} from '../actions/auth';
import CSRFToken from '../components/CSRFToken'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, FormGroup } from "react-bootstrap";
import {    
    ReqStatus
} from '../actions/types'
import WinNotification from "../components/WinNotification";

const Login = ({isAuthenticated,login}) => {
    
    const [formData,setFormData] = useState({
        email: '',
        password: ''
    });
    const [notification,setNotification]          = useState(null)


    const { email,password } = formData;
    const onChange = e => {
        setFormData( {...formData,[e.target.name]:e.target.value} )
    }
    
    const onSubmit = e =>{
        e.preventDefault();
        const fecthData = async() => {    
            try
            { 
              let dt = await login(email,password)
              if (dt.code === ReqStatus.FORBIDDEN)
              {
                setNotification({variant:"danger",
                                 message:"Usuario o contraseña incorrectos"})
              }
              else if (dt.code !== ReqStatus.OK)
              {
                setNotification({variant:"danger",
                                 message:"Error. No se pudo autenticar el usuario"})
              }
              
            }
            catch (e)
            {
                console.error(e)
                setNotification({variant:"danger",
                                 message:"Error. No se pudo autenticar el usuario"})
            }
          }
          fecthData();                    
    }

    const onCloseNotification = () =>
    {
        setNotification(null)
    }

    if (isAuthenticated)
        return <Navigate to='/inicio' replace={true} />
    
    return (
        <Container className="mt-5">
        <WinNotification notification={notification} timeout={5000} onClose={onCloseNotification}></WinNotification> 
        <h1>Acceso personal</h1>
        <Form onSubmit={e=>onSubmit(e)}>
            <CSRFToken />
            <FormGroup className="mb-3">
                <Form.Label>Usuario</Form.Label>
                <Form.Control name="email" onChange={ e=>onChange(e) } type="email" placeholder="email"/>
            </FormGroup>
            <FormGroup className="mb-3">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control name="password" onChange={ e=>onChange(e) } type="password" placeholder="Contraseña"/>
            </FormGroup>
            
            <div className="position-relative">
                <div className="position-absolute top-50 start-0">
                    <Button variant="primary" type="submit">
                        Acceder
                    </Button>
                </div>
                <div className="position-absolute top-0 end-0">
                    <Link to="/reset">He olvidado mi contraseña</Link>
                </div>
            </div>
        </Form>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }}; 

export default connect(mapStateToProps, {login})(Login)