import React  from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleImageButtonWithText from './SimpleImageButtonWithText';

const GoBack = () =>
{
    const navigate = useNavigate()

    const goBack = () =>
    {
        navigate(-1)
    }

    return ( <div>
                <SimpleImageButtonWithText 
                    image={"arrow-bar-left"}
                    text={"Volver"}
                    onClick={goBack}
                    addClass="mb-2 h5 text-secondary"
                ></SimpleImageButtonWithText>
             </div> )
}

export default GoBack