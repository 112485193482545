import './App.css';

import { HashRouter as Router,Route,Routes } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import store from './store';
import UserLanding from './pages/UserLanding.js';
import Error from './components/Error.js';
import Residencias from './pages/Residencias.js';
import AdminPatients from './pages/AdminPatients.js';
import AdminStaff from './pages/AdminStaff.js';
import UserResidencias from './pages/UserResidencias.js';
import ResetPassword from './pages/ResetPassword.js';
import Profile from './pages/Profile.js';
import ResidenciaUsers from './pages/ResidenciaUsers.js';
import Login from './pages/Login.js';
import Main from './containers/Main';
import Layout from './hocs/Layout';
import PatientsSessions from './pages/PatientsSessions.js';
import PatientRecord from './pages/PatientRecord.js';
import Graphs from './pages/Graphs.js'
import MeasureRedirect from './pages/MeasureRedirect.js';

import { QueryClient, 
         QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()         

function App() {
  return (    
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Layout>
            <Routes>
                <Route exact path='/' element={<Login/>}/>
                <Route exact path='/inicio' element={<Main> <UserLanding></UserLanding> </Main>}/>
                <Route exact path='/reset' element={<ResetPassword></ResetPassword>}/>
                <Route exact path='/perfil' element={<Main><Profile/></Main>}/>
                <Route exact path='/inicial' element={<Login/>}/>
                <Route exact path='/medidas/:id/' element={<Main allow={ ["reviewer"] }> <PatientsSessions></PatientsSessions> </Main>}/>
                <Route exact path='/medidas' element={<Main allow={ ["reviewer"] }> <MeasureRedirect></MeasureRedirect> </Main>}/>
                <Route exact path='/sesiones/residente/:id' element={<Main allow={["reviewer"]}><PatientRecord></PatientRecord> </Main>}/>
                <Route exact path='/sesiones/graficas/:id' element={<Main allow={["reviewer"]}><Graphs></Graphs></Main>}/>
                <Route exact path='/residencias' element={<Main allow={ ["admin"] }> <Residencias></Residencias> </Main>}/>
                <Route exact path='/residencia/:id/residentes' element={<Main allow={ ["admin"] }> <AdminPatients></AdminPatients> </Main>}/>
                <Route exact path='/personal' element={<Main allow={["admin"]}> <AdminStaff></AdminStaff> </Main>}/>              
                <Route exact path='/personal/:id/residencias' element={<Main allow={["admin"]}> <UserResidencias></UserResidencias></Main>}/>
                <Route exact path='/residencia/:id/personal' element={<Main allow={["admin"]}> <ResidenciaUsers></ResidenciaUsers></Main>}/>
                <Route path="*" element={<Error>Esta página no está disponible</Error>} />
            </Routes>
          </Layout>          
        </Router>    
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
