import React, {useEffect,useState} from "react";

import { getResidencias } from "../actions/getters";
import { Container } from "react-bootstrap";

import ResidenciasTable from "../components/ResidenciasTable";
import Loading from "../components/Loading";
import Error from "../components/Error";
import SimpleImageButton from "../components/SimpleImageButton";
import WinNotification from "../components/WinNotification";
import ResidenciaCreateDialog from "../dialogs/ResidenciaCreateDialog";
import ResidenciaUpdateDialog from "../dialogs/ResidenciaUpdateDialog";
import { DialogCloseOptions } from "../actions/types"; 
import { Status } from "../actions/types";
import { deleteResidencia } from "../actions/setters";
import { ReqStatus } from "../actions/types";
import HeaderTable from "../components/HeaderTable";

const Residencias = () => {

   const emptyShowUpdateDialog = {show:false,
                                  residencia:null}
 
    const [notification,setNotification]   = useState(null)
    const [status,setStatus]               = useState(Status.LOADING)
    const [data,setData]                   = useState(null)
    const [showDialog,setShowDialog]       = useState(false)
    const [showUpdateDialog,setShowUpdateDialog]  = useState(emptyShowUpdateDialog)

    useEffect(()=>
    {
      const fecthData = async() => {    
        try
        { 
          let dt = await getResidencias()            
          setData(dt)
          if (dt === null)
          {
            setStatus(Status.ERROR)
          } 
          else
          {
            setStatus(Status.OK)
          }
        }
        catch
        {
            setData(null)
            setStatus(Status.ERROR)
        }
      }
      fecthData();                    
    },[status])

    const newResidencia = () =>
    {
        setShowDialog(true)
    }

    const updateResidencia = (residencia) =>
    {
      setShowUpdateDialog({show:true,
                           residencia:residencia})
    }


    const reload = () =>
    {
      setNotification(null)
      setData(null)
      setShowDialog(false)
      setShowUpdateDialog(emptyShowUpdateDialog)
      setStatus(Status.LOADING)  
    }

    const closeUpdateDialog = (option, new_data) =>
    {
      setShowUpdateDialog(emptyShowUpdateDialog)

      try
      {
        if (option === DialogCloseOptions.ONLY_CLOSE)
        {
          return
        }        
        else if (option === DialogCloseOptions.NEW_DATA)
        {
          if(new_data !== undefined && 
             new_data !== null )
             {
                let new_vct = [...data]
                const idx = new_vct.findIndex(item => item.id === new_data.id)
                Object.assign(new_vct[idx],new_data)                
                setData(new_vct)                   
                setNotification( {variant:"success",
                                  message:`Residencia "${new_data.name}" actualizada`} )                            
              }   
              else
              {
                reload()
              }     
        }        
        else
        {
          reload()
        }  
      }
      catch (e)
      {
        console.log(e)
        reload()
      }
    }

    const closeCreateDialog = (option, new_data) =>
    {
      setShowDialog(false)     
      try
      {
        if (option === DialogCloseOptions.RELOAD)
        {
          reload()
        }
        else if (option === DialogCloseOptions.NEW_DATA)
        {
          if(new_data !== undefined && 
            new_data !== null &&
            Array.isArray(new_data))
            {
              if(new_data.length > 0)
              {                
                setData([...data,...new_data])
                if(new_data.length === 1)
                {
                  setNotification({variant:"success",
                                   message:`Residencia "${new_data[0].name} " creada`})
                }
                else
                {
                  setNotification({variant:"success",
                                  message:`Han sido creadas "${new_data.length} " residencias correctamente`})                                   
                }
              }   
              else
              {
                reload()
              }     
            }
            else
            {
              reload()
            }  
        }
      }
      catch (e)
      {
        reload()
      }
    }

    const removeResidencia = (residencia) =>
    {
        if (window.confirm(`Está seguro de que desea eliminar la residencia "${residencia.name}"`) === true) 
        {
            const fecthRequest = async() => {    
                try
                { 
                  const dt = await deleteResidencia(residencia.id)                              
                  if ( dt.code === ReqStatus.OK)
                  {
                      const id = dt.data.id
                      if (id === residencia.id)
                      {
                         const deleted_residencia = data.find(item => item.id === id)
                         const new_data = data.filter(item => item.id !== id)
                         setData(new_data)  
                         setNotification({variant:"success",
                                          message:`Residencia "${deleted_residencia.name}" eliminada`})
                      }
                      else
                      {
                        reload()
                      }
                  }
                  else
                  {
                    setNotification({variant:"danger",
                                      message:`Error eliminando la residencia "${residencia.name}"`})
                  }
                }
                catch (e)
                {
                    console.log(e)
                    reload()
                }
              }
              fecthRequest();                            
        }  
    }


    const onCloseNotification = () =>
    {
        setNotification(null)
    }


    if (status === Status.LOADING)
    {        
      return( <Loading/> )
    }
    else if(status === Status.OK)
    {
        
        return(        
        <Container className="mt-4">
            
             <WinNotification notification={notification} timeout={5000} onClose={onCloseNotification}></WinNotification>
             <ResidenciaCreateDialog show={showDialog} handleClose={closeCreateDialog}></ResidenciaCreateDialog>
             <ResidenciaUpdateDialog show={showUpdateDialog.show} handleClose={closeUpdateDialog} residencia={showUpdateDialog.residencia}></ResidenciaUpdateDialog>

           <HeaderTable reload={reload}/>           
            <div className="pt-2 ps-2 pb-2 bg-light"> 
                <h3 className="">Residencias</h3>
                  <div style={{float:"left"}}>
                    <span className="text-muted">Nº residencias:</span> {data.length}                                                                           
                  </div>
                  <div> <SimpleImageButton
                          image={'plus-square'}
                          tooltip={"Crear una residencia"}
                          onClick={newResidencia} ></SimpleImageButton>                     
                  </div>
            </div>        

            <ResidenciasTable
                residencias={data} 
                removeResidenciaCallback={removeResidencia}
                updateResidenciaCallback={updateResidencia} ></ResidenciasTable>
            
        </Container>
        
        )
    }
    else 
    {
        return ( <Error>Error cargando las residencias</Error> )
    }
}

export default Residencias