import React, { useEffect, useState, useRef }  from 'react';
import { Alert } from 'react-bootstrap';

const WinNotification = ({notification,timeout,onClose = null}) => 
{    
    const [show,setShow] = useState(false)
    
    const timer = useRef(null);

    useEffect(()=>
    {
        if(notification === undefined || notification === null)
        {            
            setShow(false)
            timer.current = null
        }
        else
        {
            if(notification.message.trim() === "")
            {
                setShow(false)    
                timer.current = null
            }
            else
            {
                setShow(true)
                if(timeout.current !== null)
                {
                    clearTimeout(timer.current)
                }
                timer.current = setTimeout(timeout_action,timeout)
            }                        
        }

    },[notification,timeout])

    const timeout_action = () =>
    {
        if(timer.current !== null)
        {
            clearTimeout(timer.current)
            timer.current = null
        }
        if(onClose !== null)
        {
            onClose()
        }
        setShow(false) 
    }

    if(notification === undefined || notification === null) return
    else
    {
        return(            
                <Alert onClose={timeout_action} show={show} variant={notification.variant} dismissible={true} className="text-center">
                    <h5>{notification.message}</h5>
                </Alert>
            )
    }
}

export default WinNotification