import React  from 'react';
import { Container, Alert } from 'react-bootstrap';

const Error2= ( {error, messages, def = "Error"}) => 
{
    const message = () => {
        const map = new Map(messages);
        const msg = map.get(error.status)
        if(msg === undefined)
        {
            return def
        }
        else
        {
            return msg
        }
    }

    return (
            <Container className='mt-5'>        
                <Alert variant={"danger"} className="text-center">
                    <h4>{message()}</h4>
                </Alert>
            </Container>     
            )
}

export default Error2