import React, {useEffect,useState} from "react";
import { useParams } from "react-router-dom";
import { Container, Table, Alert } from "react-bootstrap";

import { getUserResidencias } from "../actions/getters";
import { deleteStaffFromResidence } from "../actions/setters";

import { ReqStatus, Status } from "../actions/types";

import Loading from "../components/Loading";
import Error from "../components/Error";
import SimpleImageButton from "../components/SimpleImageButton";
import WinNotification from "../components/WinNotification";

import ResidenciaAddDialog from "../dialogs/ResidenciaAddDialog";

import { DialogCloseOptions } from "../actions/types";
import HeaderTable from "../components/HeaderTable";


const UserResidenciasRow = ({element, removeCallback}) => {

    const remove = () =>
    {
      removeCallback(element)
    }

    return (
        <tr>
            <td className="col-1">
            </td>
            <td>{element.name}</td>
            <td >
               <SimpleImageButton
                     image={'trash'}
                     tooltip={"Eliminar usuario de esta residencia"}
                     onClick={remove} ></SimpleImageButton>

            </td>
        </tr>
    )
}

const UserResidenciasTable = ({elements,removeCallback}) => {

    if (elements.length === 0)
    {
       return ( <div>
                    <Alert variant="warning" className="mt-3 h5 text-center">
                        No hay residencias asociadas
                    </Alert>
                </div> )
    }
    else
    {
        return (
            <Table className="table-light small" striped hover>
                <thead className="table-group-divider">
                    <tr>
                        <th className="col-1"></th>                        
                        <th className="text-muted col-2">Residencias asociadas</th>                        
                        <th className="col-1"></th>
                    </tr>
                </thead>
                <tbody>   
                {elements.map( (element) => (
                  <UserResidenciasRow
                         key={element.id} 
                         element={element} 
                         removeCallback={removeCallback} ></UserResidenciasRow>            
                ))}                                        
                </tbody>
            </Table>        
        )
    }
}

const UserResidencias = () => {

    let { id } = useParams();

    const [status,setStatus]                      = useState(Status.LOADING)
    const [data,setData]                          = useState(null)
    const [notification,setNotification]          = useState(null)
    const [showCreateDialog,setShowCreateDialog]  = useState(false)
  
    useEffect(()=>
    {
      const fecthData = async() => {    
        try
        { 
          let dt = await getUserResidencias(id)            
          setData(dt.data)
          if (dt.data === null)
          {
            setStatus(Status.ERROR)
          } 
          else
          {
            setStatus(Status.OK)
          }
        }
        catch
        {
            setData(null)
            setStatus(Status.ERROR)
        }
      }
      fecthData();                    
    },[id,status])

    const addResidencias = () =>
    {
      setShowCreateDialog(true)
    }

    const reload = () =>
    {
      setNotification(null)
      setShowCreateDialog(false)
      setData(null)
      setStatus(Status.LOADING)  
    }

    const removeResidencia = (element) =>
    {
        if (window.confirm(`Está seguro de que desea dar de baja la residencia "${element.name}" del usuario` ) === true) 
        {
            const fecthRequest = async() => {    
                try
                { 
                  const dt = await deleteStaffFromResidence(data.id,element.id)                              
                  if ( dt.code === ReqStatus.OK)
                  {
                      const id = dt.data.id_residencia
                      if (id === element.id)
                      {
                         const residencias = data.residencias 

                         const deleted_residencia = residencias.find(item => item.id === id)

                         setData({...data,"residencias":residencias.filter(item => item.id !== id)})  
                         setNotification({variant:"success",
                                          message:`Residencia "${deleted_residencia.name}" dada de baja del usuario "${data.first_name} ${data.last_names}"`})
                      }
                      else
                      {
                        reload()
                      }
                  }
                }
                catch (e)
                {
                    reload()
                }
              }
              fecthRequest();                            
        }  
    }

    const closeCreateDialog = (option, new_data) =>
    {
      setShowCreateDialog(false)     
      try
      {
        if (option === DialogCloseOptions.RELOAD)
        {
          reload()
        }
        else if (option === DialogCloseOptions.NEW_DATA)
        {
          if(new_data !== undefined && 
             new_data !== null &&
             Array.isArray(new_data))
             {
              if(new_data.length > 0)
              {
                setData({...data,"residencias":[...(data.residencias),...new_data]})                   
                if(new_data.length === 1)
                {
                  setNotification( {variant:"success",
                                    message:`Resdencia "${new_data[0].name}" asociada al usuario`} )
                }
                else
                {                  
                  setNotification( {variant:"success",
                                    message:`Han sido asociadas ${new_data.length} residencias correctamente`} )
                }
              }   
              else
              {
                reload()
              }     
            }
            else
            {
              reload()
            }  
        }
      }
      catch (e)
      {
        console.log(e)
        reload()
      }
    }

    const onCloseNotification = () =>
    {
        setNotification(null)
    }


    if (status === Status.LOADING)
    {        
      return( <Loading/> )
    }
    else if(status === Status.OK)
    {        
        return(        
        <Container className="mt-4">
        <WinNotification notification={notification} timeout={5000} onClose={onCloseNotification}></WinNotification> 
          
          <ResidenciaAddDialog 
              show={showCreateDialog}
              handleClose={closeCreateDialog} 
              id_staff={data.id}
              residenciasUser={data.residencias} ></ResidenciaAddDialog>

          <HeaderTable reload={reload}/>         
          <div className="pt-2 ps-2 pb-2 bg-light"> 
                <h3 className="">Personal: <span className="text-muted">{ data.last_names !== "" && data.last_names + ","} {data.first_name}</span></h3> 
                <span className="text-muted">({data.email})</span>  
                <div>
                  <div style={{float:"left"}}>
                    <span className="text-muted">Residencias asociadas:</span> {data.residencias.length}                                                                           
                  </div>
                  <div> 
                    <SimpleImageButton
                          image={'plus-square'}
                          tooltip={"Añadir residencias asociadas"}
                          onClick={addResidencias} ></SimpleImageButton>                     
                  </div>
                </div>
          </div>        
          <UserResidenciasTable
                elements={data.residencias}
                removeCallback={removeResidencia}
          ></UserResidenciasTable> 
        </Container>
        )
    }
    else 
    {
        return ( <Error>
                    <div>
                        Error cargando el usuario y sus residencias 
                        <SimpleImageButton
                            image={'arrow-clockwise'}
                            tooltip={"Recargar"}
                            addClass="p-2 h4 text-danger-emphasis"
                            onClick={reload} ></SimpleImageButton>
                    </div>
                 </Error> )
    }
}

export default UserResidencias