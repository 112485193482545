import React, {useState, useRef} from "react";
import {Navigate} from 'react-router-dom';
import { connect } from "react-redux";
import CSRFToken from '../components/CSRFToken'
import { resetPassword } from "../actions/auth";
import { Button, Form, Container, FormGroup } from "react-bootstrap";
import GoBack from "../components/GoBack";
import { ReqStatus } from "../actions/types";
import WinNotification from "../components/WinNotification";

const ResetPassword = ({isAuthenticated}) => {
    
    const [notification,setNotification]  = useState(null)
    const emailRef                        = useRef()
    const [email,setEmail] = useState({
        email: ''
    });


    const onChange = e => {
        setEmail( e.target.value )
    }
    
    const onSubmit = e =>{
        e.preventDefault();
        const fecthRequest = async() => {    
            try
            { 
              const dt = await resetPassword(email)                              
              if ( dt.code === ReqStatus.OK )
              {
                setNotification({variant:"success",
                                 message:"La nueva contraseña generada ha sido enviada a su cuenta de correo"})
                emailRef.current.value = ""
              }
              else if (dt.code === ReqStatus.NOT_FOUND)
              {
                setNotification({variant:"danger",
                                 message:"El correo especificado no es válido"})
              }
              else
              {
                setNotification({variant:"danger",
                                 message:"Error generando nueva contraseña"})
              }
            }
            catch (e)
            {
                setNotification({variant:"danger",
                                 message:"Error generando nueva contraseña"})
            }
          }
          fecthRequest();                            
    }

    const onCloseNotification = () =>
    {
        setNotification(null)
    }

    if (isAuthenticated)
        return <Navigate to='/inicio' replace={true} />
    
    return (
        <Container className="mt-5">
        <WinNotification notification={notification} timeout={5000} onClose={onCloseNotification}></WinNotification> 
        <GoBack></GoBack>
        <h1>Generar una nueva contraseña</h1>
        <Form onSubmit={e=>onSubmit(e)}>
            <CSRFToken />
            <FormGroup className="mb-3">
                <Form.Control ref={emailRef} required={true} name="email" onChange={ e=>onChange(e) } type="email" placeholder="email"/>
            </FormGroup>
            
            <Button variant="primary" type="submit">
                Generar contraseña
            </Button>
        </Form>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }}; 

export default connect(mapStateToProps)(ResetPassword)