
import React  from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';

const PatientBox = ( {patient, link_to_record } ) =>
{
    const navigate = useNavigate()

    const viewPatient = function(e){
        e.preventDefault();    
        navigate("/sesiones/residente/"+patient.id)
      }  

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Historial del residente
        </Tooltip>
      );
    

    return (
        <div className='bg-light ps-2 position-relative'>     
            <Row>
                <Col  md={8}><h2>{patient.surnames}, {patient.name}</h2></Col>
                { link_to_record !== true && <Col  className="" md={{span:4,offset:0}}><h2 className="pe-2">Historial</h2></Col> }
            </Row>       
            <div className="text-muted">
                {patient.residencia.name} (Hab. {patient.room})
            </div>
            { link_to_record === true &&
                <div className='p-2 position-absolute top-0 end-0'>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                    <Button className='btn-secondary' onClick={viewPatient}>
                        <i className='bi bi-receipt-cutoff'></i>
                    </Button>
                    </OverlayTrigger>          
                </div>
            }
        </div>

    )
    
}

export default PatientBox