import React from "react";

import { Table, Alert } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import SimpleImageButton from "./SimpleImageButton";

const ResidenciaRow = ({residencia,removeResidenciaCallback,updateResidenciaCallback}) => {
    const navigate = useNavigate()

    const getDate = (date) =>
    {
        return moment(date).format("DD/MM/YYYY")
    }

    const viewResidentes = () =>
    {
       navigate("/residencia/" + residencia.id + "/residentes")        
    }

    const viewStaff = () =>
    {
       navigate("/residencia/" + residencia.id + "/personal")        
    }

    const removeResidencia = () =>
    {
        removeResidenciaCallback(residencia)
    }

    const updateResidencia = () =>
    {
        updateResidenciaCallback(residencia)
    }

    return (
        <tr>
            <td></td>
            <td className="align-middle">{getDate(residencia.created)}</td>
            <td className="align-middle">
                <div>
                  <SimpleImageButton
                     image={'pencil'}
                     tooltip={"Editar residencia"}
                     onClick={updateResidencia} ></SimpleImageButton> {residencia.name}
                </div>
            </td>
            <td className="align-middle">
                <div>
                  <SimpleImageButton
                     image={'eye'}
                     tooltip={"Ver residentes"}
                     onClick={viewResidentes} ></SimpleImageButton> {residencia.patients_count}
                </div>
            </td>
            <td className="align-middle">      
                <div>
                  <SimpleImageButton
                     image={'eye'}
                     tooltip={"Ver personal"}
                     onClick={viewStaff} ></SimpleImageButton> {residencia.users_count}
                </div>
            </td>
            <td>            
            </td>            
        </tr>
    )
}

const ResidenciasTable = ({residencias,removeResidenciaCallback,updateResidenciaCallback}) => {
    if (residencias.length === 0)
    {
       return ( <div>
                    <Alert variant="warning" className="mt-3 h5 text-center">
                        No hay residencias
                    </Alert>
                </div> )
    }
    else
    {
        return (
            <Table className="table-light small" striped hover>
                <thead className="table-group-divider">
                    <tr>
                        <th className="col-1"></th>
                        <th className="text-muted col-2">Fecha de alta</th>
                        <th className="text-muted">Residencia</th>
                        <th className="text-muted col-2">Nº Residentes</th>
                        <th className="text-muted col-2">Personal</th>
                        <th className="col-1"></th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                {residencias.map( (residencia) => (
                <ResidenciaRow 
                        key={residencia.id} 
                        residencia={residencia}
                        removeResidenciaCallback={removeResidenciaCallback}
                        updateResidenciaCallback={updateResidenciaCallback}></ResidenciaRow>            
                ))}                       
                </tbody>
            </Table>
        )
    }
}

export default ResidenciasTable