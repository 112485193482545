import React, {useState,useEffect} from "react";
import { Modal,Button,Form,FormGroup,Alert } from 'react-bootstrap';
import { addStaffToResidence_many } from "../actions/setters";
import { getUsersSimple } from "../actions/getters";
import { ReqStatus } from "../actions/types";
import { DialogCloseOptions } from "../actions/types"; 

import RequestResult from "../components/RequestResult";
import CSRFToken from '../components/CSRFToken';

import { Status } from "../actions/types";
import Loading from "../components/Loading";


const AddUserToResidenciaDialog = ({show,
                                    residenciaWithUsers,                                    
                                    handleClose}) =>
{

    const [result,setResultV]                = useState(null);
    const [staff,setStaff]                   = useState(null);
    const [availableStaff,setAvailableStaff] = useState([]);
    const [status,setStatus]                 = useState(Status.LOADING)
    const [disabledButton,setButtonDisabled] = useState(true)

    useEffect(()=>
    {
        if (residenciaWithUsers.users !== null && staff !== null)
        {
            const available = staff.filter( (user) => residenciaWithUsers.users.findIndex( element => element.id === user.id ) === -1 )  
            let data = []
            available.forEach((element) => data.push({"selected":false,
                                                      "user":element}));      
            setAvailableStaff(data)
        }
        else
        {
            setAvailableStaff([])
        }
        setButtonDisabled(true)
    },[residenciaWithUsers,staff])

    useEffect(()=>
    {        
      const fecthData = async() => {    
        try
        { 
          let dt = await getUsersSimple()                      
          if ( dt.code !== ReqStatus.OK)
          {
            setStaff(null)
            setStatus(Status.ERROR)
          }
          else
          {
            setStaff(dt.data)
            setStatus(Status.OK)
          }
        }
        catch
        {
            setStaff(null)           
            setStatus(Status.ERROR)
        }
      }

      if(status === Status.LOADING && show)
      {
            fecthData();                    
      }
    },[status,show])


    const onChange = (e,element) => 
    {      
        element.selected = e.target.checked            
        setAvailableStaff(availableStaff)
        const number_selected = availableStaff.filter( element => element.selected === true).length
        if(number_selected > 0)
        {
            setButtonDisabled(false)
        }
        else
        {
            setButtonDisabled(true)
        }       
    }

    const setResult = (result) =>
    {
        if(result.code === ReqStatus.OK)
        {
            setResultV(null)
            const data =  result.data
            const lst  = availableStaff.filter( (user) => data.findIndex( element => element.id === user.user.id ) === -1 ) 
            setAvailableStaff(lst) 
            handleClose(DialogCloseOptions.NEW_DATA,result.data)            
        }
        else if (result.code === ReqStatus.PARTIAL_CONTENT)
        {
            const data =  result.data
            const lst  = availableStaff.filter( (user) => data.findIndex( element => element.id === user.user.id ) === -1 ) 
            setAvailableStaff(lst) 
            setResultV(result.code)           
        }
        else
        {
            setResultV(result.code)           
        }
    }

    const staffLabel = (user) =>
    {
        return <div>
            {user.first_name} {user.last_names} <span className="text-muted">( {user.email} )</span>
        </div>
    }

    const onSubmit = e =>{
        e.preventDefault();
        const fecthData = async() => {    
            try
            { 
              
              const selected = availableStaff.filter( element => element.selected === true)
              let toSend = []
              selected.forEach( element => toSend.push(element.user.id) )
              const res = await addStaffToResidence_many(residenciaWithUsers.id,toSend)            
              setResult(res)              
            }
            catch (e)
            {
                console.error(`Error: ${e}` )
                setResult(ReqStatus.ERROR)
            }
          }
          fecthData(); 
    }

    const onHide = () =>
    {
        setResultV(null)
        handleClose(DialogCloseOptions.ONLY_CLOSE,null)
    }

    const closeNotification = () =>
    {
        setResultV(null)
    }

    const generateContent = () =>
    {
        if (status === Status.LOADING)
        {        
          return( <Loading/> )
        }
        else if (status === Status.ERROR)
        {
            <div>
                <Alert variant="danger" className="mt-3 h5 text-center">
                        Error cargando el personal disponible
                </Alert>
            </div> 
        }
        else 
        {
            if(staff === null)
            {
                return (<div>
                         <Alert variant="danger" className="mt-3 h5 text-center">
                             Error cargando el personal disponible
                         </Alert>
                        </div> )
            }
            else if (staff.length === 0)
            {
                return ( 
                <div>
                    <Alert variant="warning" className="mt-3 h5 text-center">
                        No hay personal registrado
                    </Alert>
                </div> )
            }
            else if (staff.length > 0 && availableStaff.length === 0)
            {
                return ( 
                <div>
                    <Alert variant="warning" className="mt-3 h5 text-center">
                        Todo el personal está asociado a la residencia "{residenciaWithUsers.name}"
                    </Alert>
                </div> )
            }
            else 
            {
                return (
                <div>
                    <FormGroup className="mb-3">
                    <Form.Label>Personal disponible:</Form.Label>
                    {availableStaff.map( (element) => (
                        <Form.Check
                            key={element.user.id}
                            label= {staffLabel(element.user)}
                            name={element.user.id}          
                            onChange={ e => onChange(e,element) }           
                        />                                
                    ))}                                        
                    </FormGroup>
                    <RequestResult onClose={closeNotification} result={result}></RequestResult>
                </div> )
            }
        }
    }


return (
    <Modal 
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}>
    <Form onSubmit={e=>onSubmit(e)}>
        <CSRFToken />

        <Modal.Header closeButton>            
            <Modal.Title>Asociar personal a la residencia</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {generateContent()}
        </Modal.Body>

        <Modal.Footer>            
            <Button variant="primary" type="submit" disabled={disabledButton}>Asociar</Button>
        </Modal.Footer>

    </Form>    
    </Modal>
)

}

export default AddUserToResidenciaDialog
