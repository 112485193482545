import React, {useState} from "react";
import { connect } from "react-redux";
import PatientsTable from "../components/PatientsTable";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { getlastMeasures } from '../actions/getters';
import Loading from "../components/Loading";
import Error   from "../components/Error";
import { useQuery } from "@tanstack/react-query";
import Reload from "../components/Reload";
import "../css/PatientSessions.css"

const PatientsSessions = ( {authUser} ) => {

    const [val,setVal] = useState(false)    

    let { id } = useParams();

    const { isLoading, isError, data } = useQuery({ queryKey: ['session',id],
                                                    queryFn: () => getlastMeasures(id) })

    const reRender = () => { 
        setVal(!val);
    }; 
                                                
    if (isLoading)
    {        
      return( <Loading/> )
    }
    else if (isError)
    {
      return <Error>Error al cargar los datos</Error>
    }
    else
    {
        return( 
                 <Container> 
                        <div className='mt-4 mb-2'>      
                            <div style={{height:'40px'}} className="position-relative">
                                <div className="position-absolute top-50 end-0 translate-middle-y">                
                                    <Reload refresh={reRender} 
                                            queryKey={['session']}/>
                                </div>
                            </div>
                            <div className='bg-light ps-2'>
                                <Row>
                                    <Col  md={4}><h2>Residencia: <span className="text-muted">{data.name}</span></h2></Col>
                                    <Col  className="" md={{span:4,offset:4}}><h2 className="pe-2">Últimas medidas</h2></Col>
                                </Row>
                                <div className="ps-2">                            
                                   <span>Nº Residentes: <span className="text-muted">{data.patients.length}</span></span>
                                </div>                                
                            </div>
                        </div>
                        <PatientsTable data={data}></PatientsTable>
                 </Container>
               )
    }
}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth
    }}; 

export default connect(mapStateToProps)(PatientsSessions)