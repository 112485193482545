import React  from 'react';
import GoBack from './GoBack';
import SimpleImageButtonWithText from './SimpleImageButtonWithText';
import { TextSide } from './SimpleImageButtonWithText';

const HeaderTable = ({reload}) =>
{
    return (
            <div className='mt-4'>      
                <div style={{height:'40px'}} className="position-relative">
                    <div className="position-absolute top-50 start-0 translate-middle-y">
                        <GoBack />
                    </div>
                    <div className="position-absolute top-50 end-0 translate-middle-y">                
                        <SimpleImageButtonWithText 
                            image={"arrow-clockwise"}
                            text={"Recargar"}
                            onClick={reload}
                            side={TextSide.LEFT}
                            addClass="mb-2 h5 text-secondary"
                        ></SimpleImageButtonWithText>
                    </div>
                </div>
            </div>
            )
}    

export default HeaderTable