import React, {useEffect, useState} from "react";
import { Modal,Button,Form,FormGroup, Accordion } from 'react-bootstrap';
import { updateStaff } from "../actions/setters";
import { ReqStatus } from "../actions/types";
import { DialogCloseOptions } from "../actions/types"; 
import RequestResult from "../components/RequestResult";
import CSRFToken from '../components/CSRFToken';

const emptyDta = {email: '',
                  first_name: '',
                  last_names: '',
                  is_admin:false,
                  is_reviewer:false,
                  is_sender:false,
                  password:''
}

const StaffUpdateDialog = ({authUser,show,handleClose,staff}) =>
{
    const [formData,setFormData] = useState(emptyDta);
    const [result,setResultV]    = useState(null);


    useEffect(()=>{
        if(staff === undefined || staff === null)
        {
            setFormData(emptyDta)
        }
        else
        {
            setFormData({email: staff.email,
                         first_name: staff.first_name,
                         last_names: staff.last_names,
                         is_admin:staff.is_admin,
                         is_reviewer:staff.is_reviewer,
                         is_sender:staff.is_sender,
                         password:''})
        }
            
    },[staff])
    
    const onChange = e => {
        setFormData( {...formData,[e.target.name]:e.target.value.trim()} )
    }

    const onChangePassword = e => {
        formData.password = e.target.value
        setFormData( formData )
    }

    const onChangeEmail = e => {
        formData.email = e.target.value
        setFormData( formData )
    }


    const onChangeCheckbox = e => {
        setFormData( {...formData,[e.target.name]:e.target.checked} )
    }

    const setResult = (result) =>
    {
        if(result.code === ReqStatus.OK)
        {
            setFormData(emptyDta)
            setResultV(null)
            handleClose(DialogCloseOptions.NEW_DATA,result.data)            
        }
        else
        {
            setResultV(result.code)           
        }
    }

    const onSubmit = e =>{
        e.preventDefault();
        const fecthData = async() => {    
            try
            {                               
              const res = await updateStaff(staff.id,formData)            
              setResult(res)              
            }
            catch
            {
                setResult(ReqStatus.ERROR)
            }
          }
          fecthData();                    
    }

    const onHide = () =>
    {
        setFormData(emptyDta)
        setResultV(null)
        handleClose(DialogCloseOptions.ONLY_CLOSE,null)
    }


    const disabledIsAdminCheckbox = () =>
    {
        if(staff !== undefined && staff !== null)
        {
            return staff.id === authUser.id
        }
        return false;
    }

    const closeNotification = () =>
    {
        setResultV(null)
    }



return (
    <Modal 
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}>
    <Form onSubmit={e=>onSubmit(e)}>
        <CSRFToken />

        <Modal.Header closeButton>
            <Modal.Title>Actualizar datos del personal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                    
        <FormGroup className="mb-4">
                <Form.Label>Email:</Form.Label>
                <Form.Control 
                            required 
                            name="new-email" 
                            onChange={ e=>onChangeEmail(e) } 
                            type="email" 
                            autoComplete="new-email"
                            placeholder="Email"
                            defaultValue={formData.email} />
            </FormGroup>
            <hr className="hr"/>  
            <FormGroup className="mb-4">
                <Form.Label className="mt-1">Nombre:</Form.Label>
                <Form.Control 
                            required 
                            name="first_name" 
                            onChange={ e=>onChange(e) } 
                            type="text" 
                            placeholder="Nombre"
                            defaultValue={formData.first_name} /> 

                <Form.Label className="mt-1">Apellidos:</Form.Label>
                <Form.Control 
                            name="last_names" 
                            onChange={ e=>onChange(e) } 
                            type="text" 
                            placeholder="Apellidos"
                            defaultValue={formData.last_names} />                                                        
            </FormGroup>
            <hr className="hr"/>
            <FormGroup className="mb-4">

                <Form.Check
                    label= "Permiso de gestión de la plataforma"
                    name="is_admin"
                    onChange={ e=>onChangeCheckbox(e) } 
                    disabled={disabledIsAdminCheckbox()}
                    checked={formData.is_admin}
                />

                <Form.Check
                    label= "Permiso de revisión"
                    name="is_reviewer"          
                    onChange={ e=>onChangeCheckbox(e) }           
                    checked={formData.is_reviewer}
                />

                <Form.Check
                    label="Permiso de uso dispositivo de captura"
                    name="is_sender"
                    onChange={ e=>onChangeCheckbox(e) } 
                    checked={formData.is_sender}
                />
                
            </FormGroup>

            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Modificar contraseña manualmente</Accordion.Header>
                     <Accordion.Body>
                     <Form.Control 
                            name="new-password" 
                            onChange={ e=>onChangePassword(e) } 
                            type="password" 
                            autoComplete="new-password"
                            placeholder="contraseña"
                            defaultValue="" />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>



            <RequestResult onClose={closeNotification} result={result}></RequestResult>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="primary" type="submit">Actualizar</Button>
        </Modal.Footer>

    </Form>    
    </Modal>
)

}

export default StaffUpdateDialog
