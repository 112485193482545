import React from "react";
import { useSearchParams } from 'react-router-dom';

const usePaginator = (defaultSize = 10,
                      allowedSizes=[10,20,30,40,50]) => 
{
    const defaultSizeInt  = defaultSize
    const allowedSizesInt = allowedSizes
    const [searchParams, setSearchParams] = useSearchParams();

    const pageIndex = () =>
    {
        const page  = searchParams.get("page")
        let val = 0
        if (page !== null)
        {
            val = parseInt(page) - 1
            if (val < 1) val = 1;

        }
        return val    
    }
    
    const pageSize = () =>
    {
        const npage = searchParams.get("npage")
        let val = defaultSizeInt
        if (npage !== null)
        {
            if (allowedSizesInt.includes(parseInt(npage)))
            {
                    val = parseInt(npage)
            }            
        }
        return val
    }
        
    return {initialIdx:pageIndex(),
            initialSize:pageSize(),
            defaultSize: defaultSizeInt,
            allowedSizes: allowedSizesInt}
}

export default usePaginator