import React from "react";
import Loading from "../components/Loading";
import { Navigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getMyResidencias } from "../actions/getters";
import Error from "../components/Error";
import { Container,Alert } from "react-bootstrap";
const MeasureRedirect = (  ) => {

    const { isLoading, isError, data, error } = useQuery({ queryKey: ['myresidencia'],
                                                           queryFn: () => getMyResidencias()})
  
    if (isLoading)
    {
        return <Loading/>
    }
    else if (isError)
    {
        return <Error> Error cargando los datos </Error>
    }
    else if (data.length === 0)
    { 
        return ( <Container className='mt-5'>
                    <Alert className="text-center" variant={"warning"}>
                        <h4>No hay residencias asociadas a su usuario</h4>
                    </Alert>
                 </Container>    )       
    
    }
    else
    {
        return (<Navigate to={`/medidas/${data[0].id}`} replace={true} />)        
    }    
}

export default MeasureRedirect