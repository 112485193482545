import React, {useState,useEffect} from "react";
import { Modal,Button,Form,FormGroup,Alert } from 'react-bootstrap';
import { addResidenceToStaff_many } from "../actions/setters";
import { geResidenciasSimple } from "../actions/getters";
import { ReqStatus } from "../actions/types";
import { DialogCloseOptions } from "../actions/types"; 

import RequestResult from "../components/RequestResult";
import CSRFToken from '../components/CSRFToken';

import { Status } from "../actions/types";
import Loading from "../components/Loading";


const ResidenciaAddDialog = ({show,id_staff,residenciasUser,handleClose}) =>
{

    const [result,setResultV]                = useState(null);
    const [residencias,setResidencias]       = useState(null);
    const [lstResidencias,setLstResidencias] = useState([]);
    const [status,setStatus]                 = useState(Status.LOADING)
    const [disabledButton,setButtonDisabled] = useState(true)

    useEffect(()=>
    {
        if (residencias !== null)
        {
            const available = residencias.filter( (residencia) => residenciasUser.findIndex( element => element.id === residencia.id ) === -1 )  
            let data = []
            available.forEach((element) => data.push({"selected":false,
                                                      "residencia":element}));      
            setLstResidencias(data)
        }
        else
        {
            setLstResidencias([])
        }
        setButtonDisabled(true)
    },[residenciasUser,residencias])

    useEffect(()=>
    {        
      const fecthData = async() => {    
        try
        { 
          let dt = await geResidenciasSimple()                      
          if ( dt.code !== ReqStatus.OK)
          {
            setResidencias(null)
            setStatus(Status.ERROR)
          }
          else
          {
            setResidencias(dt.data)
            setStatus(Status.OK)
          }
        }
        catch
        {
            setResidencias(null)           
            setStatus(Status.ERROR)
        }
      }

      if(status === Status.LOADING && show)
      {
        fecthData();                    
      }
    },[status,show])


    const onChange = (e,element) => 
    {      
        element.selected = e.target.checked            
        setLstResidencias(lstResidencias)
        const number_selected = lstResidencias.filter( element => element.selected === true).length
        if(number_selected > 0)
        {
            setButtonDisabled(false)
        }
        else
        {
            setButtonDisabled(true)
        }       
    }

    const setResult = (result) =>
    {
        if(result.code === ReqStatus.OK)
        {
            setResultV(null)
            const data =  result.data
            const lst  = lstResidencias.filter( (residencia) => data.findIndex( element => element.id === residencia.residencia.id ) === -1 ) 
            setLstResidencias(lst) 
            handleClose(DialogCloseOptions.NEW_DATA,result.data)            
        }
        else if (result.code === ReqStatus.PARTIAL_CONTENT)
        {
            const data =  result.data
            const lst  = lstResidencias.filter( (residencia) => data.findIndex( element => element.id === residencia.residencia.id ) === -1 ) 
            setLstResidencias(lst) 
            setResultV(result.code)           
        }
        else
        {
            setResultV(result.code)           
        }
    }

    const onSubmit = e =>{
        e.preventDefault();
        const fecthData = async() => {    
            try
            { 
              
              const selected = lstResidencias.filter( element => element.selected === true)
              let toSend = []
              selected.forEach( element => toSend.push(element.residencia.id) )
              const res = await addResidenceToStaff_many(id_staff,toSend)            
              setResult(res)              
            }
            catch (e)
            {
                console.error(`Error: ${e}` )
                setResult(ReqStatus.ERROR)
            }
          }
          fecthData(); 
    }

    const onHide = () =>
    {
        setResultV(null)
        handleClose(DialogCloseOptions.ONLY_CLOSE,null)
    }

    const closeNotification = () =>
    {
        setResultV(null)
    }



    const generateContent = () =>
    {
        if (status === Status.LOADING)
        {        
          return( <Loading/> )
        }
        else if (status === Status.ERROR)
        {
            <div>
                <Alert variant="danger" className="mt-3 h5 text-center">
                    Error obteniendo las residencias
                </Alert>
            </div> 
        }
        else 
        {
            if(residencias === null)
            {
                return (<div>
                         <Alert variant="danger" className="mt-3 h5 text-center">
                           Error obteniendo las residencias
                         </Alert>
                        </div> )
            }
            else if (residencias.length === 0)
            {
                return ( 
                <div>
                    <Alert variant="warning" className="mt-3 h5 text-center">
                        No hay residencias
                    </Alert>
                </div> )
            }
            else if (residencias.length > 0 && lstResidencias.length === 0)
            {
                return ( 
                <div>
                    <Alert variant="warning" className="mt-3 h5 text-center">
                        El usuario está asociado a todas las residencias disponibles
                    </Alert>
                </div> )
            }
            else 
            {
                return (
                <div>
                    <FormGroup className="mb-3">
                    <Form.Label>Residencias:</Form.Label>
                    {lstResidencias.map( (element) => (
                        <Form.Check
                            key={element.residencia.id}
                            label= {element.residencia.name}
                            name={element.residencia.id}          
                            onChange={ e => onChange(e,element) }           
                        />                                
                    ))}                                        
                    </FormGroup>
                    <RequestResult onClose={closeNotification} result={result}></RequestResult>
                </div> )
            }
        }
    }
return (
    <Modal 
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}>
    <Form onSubmit={e=>onSubmit(e)}>
        <CSRFToken />

        <Modal.Header closeButton>
            <Modal.Title>Asociar residencias a usuario</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {generateContent()}
        </Modal.Body>

        <Modal.Footer>            
            <Button variant="primary" type="submit" disabled={disabledButton}>Añadir</Button>
        </Modal.Footer>

    </Form>    
    </Modal>
)

}

export default ResidenciaAddDialog
