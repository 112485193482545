import React,{useMemo}  from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import moment from 'moment';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Container,Tooltip,Button,OverlayTrigger,Alert } from 'react-bootstrap';

import SpO2Field from './SpO2Field.js';
import ECGField from './ECGField.js';
import Error from './Error.js';
import Operator from './Operator.js';

import { useReactTable, 
         flexRender,
         getCoreRowModel,
         getPaginationRowModel, 
         getFilteredRowModel } from '@tanstack/react-table'

import Paginator from '../components/Paginator';         
import usePaginator from '../components/usePaginator';


const PNIField = ( {value} ) =>
{
  const getTime = (str) => {
    return moment(str).format("HH:mm:ss")
    }

  return (
    <tr>
      <td className="patientcol1">#</td>
      <td className="patientcol2">{ getTime(value.date_time) }</td>
      <td className="patientcol3">PNI</td>

      <td>
      <div className="ms-2 me-auto">
      <div> <span className="fw-bold">Presión</span> <span className="text-muted">(mmHg)</span></div>
      {value.simplified_data.sys}/{value.simplified_data.dias}
      </div>
      </td>

      <td>
      <div className="ms-2 me-auto">
      <div> <span className="fw-bold">Pulso</span> <span className="text-muted">(ppm)</span></div>
      {value.simplified_data.pulse}
      </div>
      </td>
      <td></td>
      <td className='patientcolOp'><Operator operator={value.user}></Operator></td>
    </tr>
  )
}


const PatientValueSelector = ( {measure} ) =>
{
  if (measure.type.toLowerCase() === "pni")
  {
    return (<PNIField value={measure}></PNIField>)
  }
  else if (measure.type.toLowerCase() === "spo2")
  {
    return (<SpO2Field value={measure}></SpO2Field>)
  }  
  else if (measure.type.toLowerCase() === "ecg")
  {
    return (<ECGField value={measure}></ECGField>)
  }
  else
  {
    return (<tr><td colSpan={6}>Error: Medidas no válidas</td></tr>)
  }
}

const PatientValuesTable = ( {measures} ) =>
{
  const getDate = (str) => {
    return moment(str).format("DD/MM/YYYY")    
  }

  if (measures.length === 0)
  {
    return <div className="text-muted">No hay medidas</div>
  }
  else
  {
   return (
    <Table>                
    <tbody>
     <tr><td colSpan={7}>Fecha: {getDate(measures[0].date_time)}</td></tr>
     {measures.map( (measure) => (
          <PatientValueSelector key={"meas_" + measure.id} measure={measure}></PatientValueSelector>            
      ))}                       
    </tbody>
   </Table> 
   )
  }
}


const HistoryCell = ( {patient} ) =>
{
  const navigate = useNavigate()

  const viewPatient = function(e){
    e.preventDefault();    
    navigate("/sesiones/residente/"+patient.id)
  }  

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Historial del residente
    </Tooltip>
  );

   return (<div>
                  <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                   >
                   <Button className='btn-secondary' onClick={viewPatient}>
                    <i className='bi bi-receipt-cutoff'></i>
                   </Button>
                 </OverlayTrigger>          

            </div>)
}


const PatientRow = ( {patient} ) =>
{
   return (<div>
              <div className='fw-bold'>{ patient.surnames }, { patient.name }</div>
               <div className='p-3'>
                <PatientValuesTable measures={patient.last_measures}></PatientValuesTable>              
               </div>
           </div> )
}

const PatientsTable = ( {data} ) =>
{

  const columns = useMemo( () => [
    {
        id : "colroom",
        accessorKey: "room",
        header : "Habitacion",
        cell: (props)=> <span> {props.getValue()} </span>
    },
    {
        id : "colresi",
        accessorFn: row => row,
        header : "Residente",
        cell: (props)=> <PatientRow patient={props.getValue()}/>
    },
    {
        id : "colhist",
        accessorFn: row => row,
        header : "",
        cell: (props)=> <HistoryCell patient={props.getValue()}/>
    }
  ] , [] ) 



  const {initialIdx,
         initialSize,
         defaultSize,
         allowedSizes} = usePaginator(10,[10,20,30,40,50])

  const table = useReactTable({data: data.patients,
          columns,
          initialState: { 
             pagination: {
                 pageSize:  initialSize,
                 pageIndex: initialIdx
             }
         },
         getCoreRowModel:getCoreRowModel(),
         getPaginationRowModel: getPaginationRowModel(),
         getFilteredRowModel: getFilteredRowModel()})


    if (data === null)
    {
      return ( <Error>
                   Error obteniendo los pacientes
                </Error>  )                
    }
    else if (data.patients.length === 0)
    {
      return ( <Container className='mt-5'>
                  <Alert className="text-center" variant={"warning"}>
                    <h4>No hay residentes asociados a su usuario</h4>
                  </Alert>
               </Container>    )       
    }

            return  ( 
              <div>
              <Table className="table-light small" striped hover> 
              <thead>
                  {table.getHeaderGroups().map((group)=>(
                      <tr key={group.id}>
                          {group.headers.map((header)=>(
                              <th key={header.id}>
                                  {header.column.columnDef.header}
                              </th>
                          ))}
                      </tr>                            
                  ))}
              </thead>
              <tbody>
              {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                      {row.getVisibleCells().map((cell)=>(                                    
                          <td key={cell.id} className={`cl_${cell.id.split('_').at(-1)}`}> 
                              { flexRender(cell.column.columnDef.cell,
                                           cell.getContext()) } 
                          </td>
                      ))}
                  </tr>
                  ))}                        
              </tbody>
           </Table>
           <div>
           </div>
              <Paginator 
                      table={table}
                      defaultSize={defaultSize} 
                      allowedSizes={allowedSizes}/>
           </div>)
}

export default PatientsTable
