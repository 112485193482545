import React  from 'react';
import { useQueryClient } from '@tanstack/react-query';
import SimpleImageButtonWithText from './SimpleImageButtonWithText';
import { TextSide } from './SimpleImageButtonWithText';

const Reload = ({queryKey, refresh = null}) =>
{
    const queryClient = useQueryClient()

    const reload = () =>
    {
        queryClient.invalidateQueries({ queryKey: queryKey })
        if (refresh != null)
        {
            refresh()
        }
    }

    return ( <div>
                <SimpleImageButtonWithText 
                    image={"arrow-clockwise"}
                    text={"Recargar"}
                    onClick={reload}
                    side={TextSide.LEFT}
                    addClass="mb-2 h5 text-secondary"
                ></SimpleImageButtonWithText>
             </div> )
}

export default Reload