import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { ReqStatus } from '../actions/types';

const RequestResult = ({result, messages, onClose = null }) =>
{   
    const [rslt,setResult] = useState(null)

    const defaultMessages = [
        [ReqStatus.OK, "Acción realizada con éxito"],
        [ReqStatus.CREATED, "Elemento creado con éxito"],
        [ReqStatus.ALREADY_EXISTS, "Error: Elemento ya existe"],
        [ReqStatus.ERROR, "Error: No se pudo completar la acción"],
        [ReqStatus.UNAUTHORIZED, "Error: No está autenticado"],
        [ReqStatus.FORBIDDEN,"Error: No está autorizado a realizar esta acción"],
        [ReqStatus.NOT_FOUND,"Error: Elemento no encontrado"],
        [ReqStatus.TIMEOUT,"Inténtelo en unos minutos"],
        [ReqStatus.BAD_REQUEST, "Error"],
        [ReqStatus.SERVER_ERROR, "Error"],
        [ReqStatus.PARTIAL_CONTENT, "Operación parcialmente realizada"]
              
    ];    

    useEffect(()=>{
        setResult(result)
    },[result])

    const getVariant = () =>
    {
        if (result === ReqStatus.OK || result === ReqStatus.CREATED)
        {return "success"}
        else 
        {return "danger"}
    }

    const getMessage = () =>
    {
        if (messages === undefined || messages === null)
        {
            const map = new Map(defaultMessages);
            const msg = map.get(result)
            if(msg === undefined)
            {
                return "Error: Error no esperado"
            }
            else
            {
                return msg
            }
        }
        else
        {
            const map = new Map(messages);
            const msg = map.get(result)
            if(msg === undefined)
            {
                const defMap = new Map(defaultMessages);
                const defMsg = defMap.get(result)
                if(defMsg === undefined)
                {
                    return "Error: Error no esperado"
                }
                else
                {
                    return defMsg
                }
            }
            else
            {
                return msg
            }
        }
    }

    const close = () =>
    {
        setResult(null)
        if(onClose)
        {
            onClose()
        }
    }

    if(rslt === null)
    {
        return <div></div>
    }
    else
    {
        return (
            <Alert className="mt-2" onClose={close} variant={getVariant()} dismissible={true}>
                {getMessage()}
            </Alert>
        )
    }
}

export default RequestResult