import React, {useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import { Container, Table, Alert } from "react-bootstrap";

import { getStaff } from "../actions/getters";
import { deleteStaff } from "../actions/setters";

import { connect } from "react-redux";

import { ReqStatus, Status } from "../actions/types";

import Loading from "../components/Loading";
import Error from "../components/Error";
import SimpleImageButton from "../components/SimpleImageButton";
import WinNotification from "../components/WinNotification";
import { getDate } from "../actions/tools";

import StaffCreateDialog from "../dialogs/StaffCreateDialog";
import StaffUpdateDialog from "../dialogs/StaffUpdateDialog";

import { DialogCloseOptions } from "../actions/types";
import HeaderTable from "../components/HeaderTable";

const AdminStaffRow = ({authUser, element, removeCallback, updateCallback}) => {

    const navigate = useNavigate()

    const remove = () =>
    {
        removeCallback(element)
    }

    const update = () =>
    {
        updateCallback(element)
    }

    const viewResidencias = () =>
    {
      navigate(`/personal/${element.id}/residencias`)
    }

    const img = (enabled) =>
    {
        if(enabled)
        {
            return (  <i className="bi bi-check2 text-success" /> )
        }
        else
        {
            return (  <i className="bi bi-x-lg text-danger" /> )
        }
    }

    return (
        <tr>
            <td>
              <SimpleImageButton
                     image={'pencil'}
                     tooltip={"Editar personal"}
                     onClick={update} ></SimpleImageButton>
            </td>
            <td>{getDate(element.created)}</td>
            <td>{element.email}</td>
            <td>{element.last_names}</td>
            <td>{element.first_name}</td>
            <td className="align-middle">
                <div>
                  <SimpleImageButton
                     image={'eye'}
                     tooltip={"Ver residencias asociadas"}
                     onClick={viewResidencias} ></SimpleImageButton> {element.residencias_count}
                </div>
            </td>
            <td className="text-center">{img(element.is_admin)}</td>
            <td className="text-center">{img(element.is_reviewer)}</td>
            <td className="text-center" >{img(element.is_sender)}</td>
            <td >
               { authUser.id !== element.id &&
                  <SimpleImageButton
                     image={'trash'}
                     tooltip={"Dar de baja personal"}
                     onClick={remove} >                  
                  </SimpleImageButton>
               }
            </td>
        </tr>
    )
}

const AdminStaffTable = ({authUser,elements,removeCallback,updateCallback}) => {


    if (elements.length === 0)
    {
       return ( <div>
                    <Alert variant="warning" className="mt-3 h5 text-center">
                        No hay personal
                    </Alert>
                </div> )
    }
    else
    {
        return (
            <Table className="table-light small" striped hover>
                <thead className="table-group-divider">
                    <tr>
                        <th className="col"></th>
                        <th className="text-muted"></th>
                        <th className="text-muted col"></th>
                        <th className="text-muted col-2"></th>
                        <th className="text-muted col-2"></th>
                        <th className="text-muted col"></th>
                        <th className="text-muted text-center" colSpan={3}>Permisos</th>                        
                        <th className="col-1"></th>
                    </tr>
                    <tr>
                        <th className="col"></th>
                        <th className="text-muted">Fecha de alta</th>
                        <th className="text-muted col">email</th>
                        <th className="text-muted col-2">Apellidos</th>
                        <th className="text-muted col-2">Nombre</th>
                        <th className="text-muted col">residencias</th>
                        <th className="text-muted text-center col">Gestión</th>
                        <th className="text-muted text-center col">Revisión</th>
                        <th className="text-muted text-center col">Dispositivo captura</th>
                        <th className="col-1"></th>
                    </tr>
                </thead>
                <tbody>   
                {elements.map( (element) => (
                  <AdminStaffRow
                         authUser={authUser}
                         key={element.id} 
                         element={element} 
                         removeCallback={removeCallback}
                         updateCallback={updateCallback} ></AdminStaffRow>            
                ))}                                        
                </tbody>
            </Table>        
        )
    }
}

const AdminStaff = ({authUser}) => {

    const emptyShowUpdateDialog = {show:false,
                                   element:null}

    const [notification,setNotification]          = useState(null)
    const [status,setStatus]                      = useState(Status.LOADING)
    const [data,setData]                          = useState(null)
    const [showCreateDialog,setShowCreateDialog]  = useState(false)
    const [showUpdateDialog,setShowUpdateDialog]  = useState(emptyShowUpdateDialog)

    useEffect(()=>
    {
      const fecthData = async() => {    
        try
        { 
          let dt = await getStaff()            
          setData(dt.data)
          if (dt.data === null)
          {
            setStatus(Status.ERROR)
          } 
          else
          {
            setStatus(Status.OK)
          }
        }
        catch
        {
            setData(null)
            setStatus(Status.ERROR)
        }
      }
      fecthData();                    
    },[status])

    const create = () =>
    {
      setShowCreateDialog(true)
    }

    const update = (element) =>
    {
      setShowUpdateDialog({show:true,
                           element:element})
    }

    const reload = () =>
    {
      setNotification(null)
      setShowCreateDialog(false)
      setShowUpdateDialog(emptyShowUpdateDialog)
      setData(null)
      setStatus(Status.LOADING)  
    }

    const remove = (element) =>
    {
        if (window.confirm(`Está seguro de que desea eliminar a "${element.first_name} ${element.last_names}"`) === true) 
        {
            const fecthRequest = async() => {    
                try
                { 
                  const dt = await deleteStaff(element.id)                              
                  if ( dt.code === ReqStatus.OK)
                  {
                      const id = dt.data.id
                      if (id === element.id)
                      {
                         setData([data.filter(item => item.id !== id)])  
                         setNotification({variant:"success",
                                          message:`"${element.first_name} ${element.last_names}" dado de baja`})
                      }
                      else
                      {
                        reload()
                      }
                  }
                }
                catch (e)
                {
                    console.log(e)
                    reload()
                }
              }
              fecthRequest();                            
        } 
    }


    const closeUpdateDialog = (option, new_data) =>
    {
      setShowUpdateDialog(emptyShowUpdateDialog)

      try
      {
        if (option === DialogCloseOptions.ONLY_CLOSE)
        {
          return
        }        
        else if (option === DialogCloseOptions.NEW_DATA)
        {
          if(new_data !== undefined && 
             new_data !== null )
             {
                let staff = [...data]
                const idx = staff.findIndex(item => item.id === new_data.id)
                Object.assign(staff[idx],new_data)                
                setData([...staff])                   
                setNotification( {variant:"success",
                                  message:`"${new_data.first_name}  ${new_data.last_names}" actualizado`} )                            
              }   
              else
              {
                reload()
              }     
        }        
        else
        {
          reload()
        }  
      }
      catch (e)
      {
        console.log(e)
        reload()
      }
    }

    const closeCreateDialog = (option, new_data) =>
    {
      setShowCreateDialog(false)     
      try
      {
        if (option === DialogCloseOptions.RELOAD)
        {
          reload()
        }
        else if (option === DialogCloseOptions.NEW_DATA)
        {
          if(new_data !== undefined && 
             new_data !== null &&
             Array.isArray(new_data))
             {
              if(new_data.length > 0)
              {
                setData([...data,...new_data])                   
                if(new_data.length === 1)
                {
                  setNotification( {variant:"success",
                                    message:`"${new_data[0].first_name}  ${new_data[0].last_names}" dado de alta`} )
                }
                else
                {                  
                  setNotification( {variant:"success",
                                    message:`Han sido dados de alta ${new_data.length} personas como personal correctamente`} )
                }
              }   
              else
              {
                reload()
              }     
            }
            else
            {
              reload()
            }  
        }
      }
      catch (e)
      {
        console.log(e)
        reload()
      }
    }

    const onCloseNotification = () =>
    {
        setNotification(null)
    }


    if (status === Status.LOADING)
    {        
      return( <Loading/> )
    }
    else if(status === Status.OK)
    {
        
        return(        
        <Container className="mt-4">

            <WinNotification notification={notification} timeout={5000} onClose={onCloseNotification}></WinNotification> 
            <StaffCreateDialog show={showCreateDialog} handleClose={closeCreateDialog} residencia={data}></StaffCreateDialog>
            <StaffUpdateDialog authUser={authUser} show={showUpdateDialog.show} handleClose={closeUpdateDialog} staff={showUpdateDialog.element}></StaffUpdateDialog>

            <HeaderTable reload={reload}/>         
            <div className="pt-2 ps-2 pb-2 bg-light"> 
                <h3 className="">Personal</h3>
                <div style={{float:"left"}}>
                  <span className="text-muted">Nº usuarios dados de alta:</span> {data.length}                                                                           
                </div>
                <div> <SimpleImageButton
                        image={'plus-square'}
                        tooltip={"Añadir personal"}
                        onClick={create} ></SimpleImageButton>                     
                </div>
            </div>        
            <AdminStaffTable
                authUser={authUser}
                elements={data}
                removeCallback={remove}
                updateCallback={update} ></AdminStaffTable>
        </Container>
        )
    }
    else 
    {
        return ( <Error>
                    <div>
                        Error cargando el personal 
                        <SimpleImageButton
                            image={'arrow-clockwise'}
                            tooltip={"Recargar"}
                            addClass="p-2 h4 text-danger-emphasis"
                            onClick={reload} ></SimpleImageButton>
                    </div>
                 </Error> )
    }
}


const mapStateToProps = (state) => {
  return {
      authUser: state.auth
  }}; 

export default connect(mapStateToProps)(AdminStaff)