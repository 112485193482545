export const LOGIN_SUCCESS  = 'LOGIN_SUCCESS'
export const LOGIN_FAIL     = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL    = 'LOGOUT_FAIL'

export const ReqStatus = 
{
  OK              : 0,
  CREATED         : 1,    
  ALREADY_EXISTS  : 2,
  ERROR           : 3,
  UNAUTHORIZED    : 4,
  FORBIDDEN       : 5,
  NOT_FOUND       : 6,
  TIMEOUT         : 7,
  BAD_REQUEST     : 8,
  SERVER_ERROR    : 9,
  PARTIAL_CONTENT : 10
}


export const DialogCloseOptions = 
{
  ONLY_CLOSE       : 0,
  RELOAD_DATA      : 1,
  NEW_DATA         : 2,
  NEW_PARTIAL_DATA : 3
}

export const Status =
{
  LOADING : 0,
  ERROR   : 1,
  OK      : 2
}
