import React, {useRef,useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
import { Col,Row,Pagination, Form } from 'react-bootstrap';

const Paginator = ({table, allowedSizes = [10,20,30,40,50], defaultSize = 10}) =>
{    
    const pageRef = useRef()
    const [searchParams, setSearchParams] = useSearchParams();

    const pageN = () =>
    {
        const page  = searchParams.get("page")
        let val = 1
        if (page !== null)
        {
            val = parseInt(page)
            if (val < 1)
            {
                val = 1
            } 
            else if (page > table.getPageCount())
            {
                val = table.getPageCount()
            }
        }
        return val    
    }

    const pageSize = () =>
    {
        const npage = searchParams.get("npage")
        let val = defaultSize
        if (npage !== null)
        {
            if (allowedSizes.includes(parseInt(npage)))
            {
                val = parseInt(npage)
            }            
        }
        return val
    }

    useEffect(()=>{
        const pag  = pageN()
        const size = pageSize()
        if (table.getState().pagination.pageSize !== size)
        {
            table.setPageSize(size)
        }
        if(table.getState().pagination.pageIndex !== pag - 1)
        {
            const npages = table.getPageCount();   
            if (pag <= 1)
            {
                table.firstPage()
                pageRef.current.value = "1"
            }
            else if (pag >= npages)
            {
                table.lastPage()
                pageRef.current.value = table.getPageCount().toString()
            }
            else
            {
                table.setPageIndex(pag - 1)    
                pageRef.current.value = pag.toString()
            }
        }
        else
        {
            if ( pageRef.current.value ===  pag)
            {
                pageRef.current.value = pag
            }
        }
    },[searchParams])



    const updateParams = (size,page) =>
    {
        const params = new URLSearchParams([
            ["npage", size],
            ["page",  page],
            ]);
        setSearchParams(params,{ replace: true })  //Replace = true para que no se añada un elemento nuevo a la pila de URL's. Esto hace que funcione el volver de manera adecuada     
    }    

    const changeSize = (size) =>
    {
        updateParams(size,
                        1)        
    }

    const toPage = (page) =>
    {   
        if(page > 0 && page <= table.getPageCount())        
        {
            if(page !== table.getState().pagination.pageIndex + 1)
            {
                updateParams(table.getState().pagination.pageSize,
                                page)        
            }            
        }        
    }
    
    const toFirstPage = () =>
    {
        if (table.getCanPreviousPage())
        {
            updateParams(table.getState().pagination.pageSize,
                            1)        
        }        
    }

    const toLastPage = () =>
    {
        if (table.getCanNextPage())
        {
            updateParams(table.getState().pagination.pageSize,
                            table.getPageCount())
        }        
    }
    
    const toPreviousPage = () =>
    {
        if (table.getCanPreviousPage())
        {
            updateParams(table.getState().pagination.pageSize,
                            table.getState().pagination.pageIndex)        
        }        
    }

    const toNextPage = () =>
    {
        if (table.getCanNextPage())
        {
            updateParams(table.getState().pagination.pageSize,
                            table.getState().pagination.pageIndex + 2)        
        }        
    }
    
    const checkValue = e =>
    {        
        let value = e.target.value.replace(/\D/g,'');  
        value = value.replace(/^0*/g,'')        
        if (value !== "")
        {            
            let intValue = parseInt(value)
            while(intValue > table.getPageCount())
            {
                value = value.slice(0, -1)
                if (value === "")
                {
                    value = table.getPageCount().toString()
                }
                intValue = parseInt(value)        
            }
        }
        if (value !== e.target.value)
        {
            e.target.value = value
        }              
    }

    const focusOut = e =>
    {
        checkValue(e)        
        if (e.target.value === "")
        {
            toPage(1)
        }
        else
        {
            toPage(parseInt(e.target.value))
        }
    }        
    
    const handleKeyDown = e =>
    {
        if (e.key === 'Enter') 
        {
            toPage(pageRef.current.value)
        }        
    }

    return (<Row>
            <Col md={2}>
                <Pagination>
                    <Pagination.First  onClick={()=>toFirstPage()}
                                        disabled={!table.getCanPreviousPage()} />
                    <Pagination.Prev   onClick={()=>toPreviousPage()}
                                        disabled={!table.getCanPreviousPage()}/>
                    <Pagination.Next   onClick={()=>toNextPage()}
                                        disabled={!table.getCanNextPage()}/>
                    <Pagination.Last   onClick={()=>toLastPage()}
                                        disabled={!table.getCanNextPage()}/>
                </Pagination>
            </Col>
            <Col md={3}>
                <Row>
                    <Col className='d-flex align-items-center justify-content-end pe-0'>
                        Página:
                    </Col> 
                    <Col> 
                            <Form.Control 
                                    ref = {pageRef}
                                    name="page" 
                                    onBlur    = { e=>focusOut(e)      }
                                    onKeyDown = { e=>handleKeyDown(e) } 
                                    onChange  = { e=>checkValue(e)    } 
                                    type="text"                                                                         
                                    defaultValue = { (table.getState().pagination.pageIndex + 1).toString() } />                        
                    </Col>
                    <Col className='d-flex align-items-center justify-content-start ps-0'>
                        de {table.getPageCount()}
                    </Col> 
                </Row>
            </Col>
            <Col md={3}>
                <Row>
                    <Col className='d-flex align-items-center justify-content-end pe-0'>
                        Número de filas:
                    </Col> 
                        <Col>
                            <Form.Select
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                changeSize(parseInt(e.target.value))
                            }}>

                            {allowedSizes.map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                            </Form.Select>
                        </Col>
                </Row>
            </Col>
            </Row>)   

}
    
export default Paginator