import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import {Navigate} from 'react-router-dom';

const UserLanding = ({authUser}) =>
{
    if( authUser.isReviewer )
        return (<Navigate to='/medidas' replace={true} />)

    return (<Container className="mt-5 text-center">
                <h1>Bienvenido, {authUser.first_name}</h1>
            </Container>)

}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth
    }}; 

export default connect(mapStateToProps)(UserLanding)