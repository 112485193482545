import React, {useEffect,useState} from "react";
import { useParams } from "react-router-dom";
import { Container, Table, Alert } from "react-bootstrap";

import { getPatientOfResidencia } from "../actions/getters";
import { deletePatient } from "../actions/setters";

import { ReqStatus, Status } from "../actions/types";

import Loading from "../components/Loading";
import Error from "../components/Error";
import SimpleImageButton from "../components/SimpleImageButton";
import WinNotification from "../components/WinNotification";
import { getDate } from "../actions/tools";

import PatientCreateDialog from "../dialogs/PatientCreateDialog";
import PatientUpdateDialog from "../dialogs/PatientUpdateDialog";

import { DialogCloseOptions } from "../actions/types";
import HeaderTable from "../components/HeaderTable";

const AdminPatientRow = ({patient, removePatientCallback, updatePatientCallback}) => {

    const removePatient = () =>
    {
        removePatientCallback(patient)
    }

    const updatePatient = () =>
    {
        updatePatientCallback(patient)
    }


    return (
        <tr>
            <td>
              <SimpleImageButton
                     image={'pencil'}
                     tooltip={"Editar residente"}
                     onClick={updatePatient} ></SimpleImageButton>
            </td>
            <td>{getDate(patient.created)}</td>
            <td>{patient.room}</td>
            <td>{patient.surnames}</td>
            <td>{patient.name}</td>
            <td >
               <SimpleImageButton
                     image={'trash'}
                     tooltip={"Dar de baja residente"}
                     onClick={removePatient} ></SimpleImageButton>

            </td>
        </tr>
    )
}

const AdminPatientsTable = ({patients,removePatientCallback,updatePatientCallback}) => {


    if (patients.length === 0)
    {
       return ( <div>
                    <Alert variant="warning" className="mt-3 h5 text-center">
                        No hay residentes
                    </Alert>
                </div> )
    }
    else
    {
        return (
            <Table className="table-light small" striped hover>
                <thead className="table-group-divider">
                    <tr>
                        <th className="col-1"></th>
                        <th className="text-muted col-2">Fecha de alta</th>
                        <th className="text-muted col-2">Habitación</th>
                        <th className="text-muted">Apellidos</th>
                        <th className="text-muted">Nombre</th>
                        <th className="col-1"></th>
                    </tr>
                </thead>
                <tbody>   
                {patients.map( (patient) => (
                  <AdminPatientRow
                         key={patient.id} 
                         patient={patient} 
                         removePatientCallback={removePatientCallback}
                         updatePatientCallback={updatePatientCallback} ></AdminPatientRow>            
                ))}                                        
                </tbody>
            </Table>        
        )
    }
}

const AdminPatients = () => {

    let { id } = useParams();

    const emptyShowUpdateDialog = {show:false,
                                   patient:null}

    const [notification,setNotification]          = useState(null)
    const [status,setStatus]                      = useState(Status.LOADING)
    const [data,setData]                          = useState(null)
    const [showCreateDialog,setShowCreateDialog]  = useState(false)
    const [showUpdateDialog,setShowUpdateDialog]  = useState(emptyShowUpdateDialog)

    useEffect(()=>
    {
      const fecthData = async() => {    
        try
        { 
          let dt = await getPatientOfResidencia(id)            
          setData(dt.data)
          if (dt.data === null)
          {
            setStatus(Status.ERROR)
          } 
          else
          {
            setStatus(Status.OK)
          }
        }
        catch
        {
            setData(null)
            setStatus(Status.ERROR)
        }
      }
      fecthData();                    
    },[id,status])

    const newPatient = () =>
    {
      setShowCreateDialog(true)
    }

    const updatePatient = (patient) =>
    {
      setShowUpdateDialog({show:true,
                           patient:patient})
    }

    const reload = () =>
    {
      setNotification(null)
      setShowCreateDialog(false)
      setShowUpdateDialog(emptyShowUpdateDialog)
      setData(null)
      setStatus(Status.LOADING)  
    }

    const removePatient = (patient) =>
    {
        if (window.confirm("Está seguro de que desea eliminar a " + patient.name + " " + patient.surnames) === true) 
        {
            const fecthRequest = async() => {    
                try
                { 
                  const dt = await deletePatient(patient.id)                              
                  if ( dt.code === ReqStatus.OK)
                  {
                      const id = dt.data.id
                      if (id === patient.id)
                      {

                         const patients = data.patients 

                         const deleted_patient = patients.find(item => item.id === id)

                         setData({...data,"patients":patients.filter(item => item.id !== id)})  
                         setNotification({variant:"success",
                                          message:`Residente ${deleted_patient.name} ${deleted_patient.surnames} dado de baja`})
                      }
                      else
                      {
                        reload()
                      }
                  }
                }
                catch (e)
                {
                    console.log(e)
                    reload()
                }
              }
              fecthRequest();                            
        }  
    }


    const closeUpdateDialog = (option, new_data) =>
    {
      setShowUpdateDialog(emptyShowUpdateDialog)

      try
      {
        if (option === DialogCloseOptions.ONLY_CLOSE)
        {
          return
        }        
        else if (option === DialogCloseOptions.NEW_DATA)
        {
          if(new_data !== undefined && 
             new_data !== null )
             {
                let patients = [...data.patients]
                const idx = patients.findIndex(item => item.id === new_data.id)
                Object.assign(patients[idx],new_data)                
                setData({...data,"patients":patients})                   
                setNotification( {variant:"success",
                                  message:`Residente "${new_data.name}  ${new_data.surnames}" actualizado`} )                            
              }   
              else
              {
                reload()
              }     
        }        
        else
        {
          reload()
        }  
      }
      catch (e)
      {
        console.log(e)
        reload()
      }
    }

    const closeCreateDialog = (option, new_data) =>
    {
      setShowCreateDialog(false)     
      try
      {
        if (option === DialogCloseOptions.RELOAD)
        {
          reload()
        }
        else if (option === DialogCloseOptions.NEW_DATA)
        {
          if(new_data !== undefined && 
             new_data !== null &&
             Array.isArray(new_data))
             {
              if(new_data.length > 0)
              {
                setData({...data,"patients":[...(data.patients),...new_data]})                   
                if(new_data.length === 1)
                {
                  setNotification( {variant:"success",
                                    message:`Residente "${new_data[0].name}  ${new_data[0].surnames}" dado de alta`} )
                }
                else
                {                  
                  setNotification( {variant:"success",
                                    message:`Han sido dados de alta ${new_data.length} residentes correctamente`} )
                }
              }   
              else
              {
                reload()
              }     
            }
            else
            {
              reload()
            }  
        }
      }
      catch (e)
      {
        console.log(e)
        reload()
      }
    }

    const onCloseNotification = () =>
    {
        setNotification(null)
    }


    if (status === Status.LOADING)
    {        
      return( <Loading/> )
    }
    else if(status === Status.OK)
    {
        
        return(        
        <Container className="mt-4">

          <WinNotification notification={notification} timeout={5000} onClose={onCloseNotification}></WinNotification> 
          <PatientCreateDialog show={showCreateDialog} handleClose={closeCreateDialog} residencia={data}></PatientCreateDialog>
          <PatientUpdateDialog show={showUpdateDialog.show} handleClose={closeUpdateDialog} patient={showUpdateDialog.patient}></PatientUpdateDialog>

          <HeaderTable reload={reload}/>        
          <div className="pt-2 ps-2 pb-2 bg-light"> 
                <h3 className="">Residencia: <span className="text-muted">{data.name}</span> </h3>
                  <div style={{float:"left"}}>
                    <span className="text-muted">Nº residentes:</span> {data.patients.length}                                                                           
                  </div>
                  <div> <SimpleImageButton
                          image={'plus-square'}
                          tooltip={"Añadir un residente"}
                          onClick={newPatient} ></SimpleImageButton>                     
                  </div>
            </div>        

            <AdminPatientsTable
                patients={data.patients}
                removePatientCallback={removePatient}
                updatePatientCallback={updatePatient} ></AdminPatientsTable>
        </Container>
        )
    }
    else 
    {
        return ( <Error>
                    <div>
                        Error cargando los pacientes 
                        <SimpleImageButton
                            image={'arrow-clockwise'}
                            tooltip={"Recargar"}
                            addClass="p-2 h4 text-danger-emphasis"
                            onClick={reload} ></SimpleImageButton>
                    </div>
                 </Error> )
    }
}

export default AdminPatients