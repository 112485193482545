import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip,Button,OverlayTrigger } from 'react-bootstrap';

const ViewGraphButton = ( {id} ) => {

    const navigate = useNavigate()

    const viewGraph = function(e){
        e.preventDefault();    
        navigate("/sesiones/graficas/"+id)
      }  

      const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Ver gráficas de la sesión
        </Tooltip>
      );
    
      return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
        <Button onClick={viewGraph}>
            <i className='bi bi-graph-up'></i>
        </Button>
        </OverlayTrigger>          
      )
}

export default ViewGraphButton