import React from 'react';

const ComplexValueField = ( {name, value, units} ) =>
{
  return (
          <div className="ms-2 me-auto">
              <div> <span className="fw-bold">{name}</span>  <span className="text-muted">({units})</span></div>
              <div>
                <span className="text-muted">Media: </span>  {value.mean}
              </div>
              <div>
                <span className="text-muted">Min:</span> {value.min}  -- <span className="ml-2 text-muted">Max:</span> {value.max}
              </div>
          </div> 
         )
}

export default ComplexValueField