import React from 'react';
import moment from 'moment';
import "bootstrap-icons/font/bootstrap-icons.css";
import ComplexValueField from './ComplexValueField';
import ViewGraphButton from './ViewGraphButton';
import Operator from './Operator';

const ECGField = ( {value} ) =>
{
  const getTime = (str) => {
    return moment(str).format("HH:mm:ss")
    }

  return(<tr>
          <td className="patientcol1">#</td>
          <td className="patientcol2">{ getTime(value.date_time) }</td>
          <td className="patientcol3">ECG</td>
          <td>     
            <ComplexValueField name={"Ritmo cardiaco"} value={value.simplified_data.freq} units={"lpm"}></ComplexValueField>             
          </td>
          <td>            
          </td>
          <td className="align-middle patientcolHist">
            <ViewGraphButton id={value.id}></ViewGraphButton>            
          </td>

          <td className="patientcolOp">
            <Operator operator={value.user}></Operator>
          </td>

         </tr>)
}

export default ECGField