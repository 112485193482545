import React, {useState} from "react";
import { Modal,Button,Form,FormGroup } from 'react-bootstrap';
import { createPatient } from "../actions/setters";
import { ReqStatus } from "../actions/types";
import { DialogCloseOptions } from "../actions/types"; 
import RequestResult from "../components/RequestResult";
import CSRFToken from '../components/CSRFToken';

const PatientCreateDialog = ({show,handleClose,residencia}) =>
{
    const emptyDta = {name:    '',
                      surnames:'',
                      room:    ''}

    const [formData,setFormData] = useState(emptyDta);
    const [result,setResultV]    = useState(null);
    
    const onChange = e => {
        setFormData( {...formData,[e.target.name]:e.target.value.trim()} )
    }

    const setResult = (result) =>
    {
        if(result.code === ReqStatus.CREATED)
        {
            setFormData(emptyDta)
            setResultV(null)
            handleClose(DialogCloseOptions.NEW_DATA,result.data)            
        }
        else
        {
            setResultV(result.code)           
        }
    }

    const onSubmit = e =>{
        e.preventDefault();
        const fecthData = async() => {    
            try
            { 
              const res = await createPatient(residencia.id,formData)            
              setResult(res)              
            }
            catch
            {
                setResult(ReqStatus.ERROR)
            }
          }
          fecthData();                    
    }

    const onHide = () =>
    {
        setFormData(emptyDta)
        setResultV(null)
        handleClose(DialogCloseOptions.ONLY_CLOSE,null)
    }

    const closeNotification = () =>
    {
        setResultV(null)
    }



return (
    <Modal 
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}>
    <Form onSubmit={e=>onSubmit(e)}>
        <CSRFToken />

        <Modal.Header closeButton>
            <Modal.Title>Dar de alta residente</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                    
            <FormGroup className="mb-3">
                <Form.Label>Habitación:</Form.Label>
                <Form.Control 
                            required 
                            name="room" 
                            onChange={ e=>onChange(e) } 
                            type="text" 
                            placeholder="Habitación"
                            defaultValue="" />                            
            </FormGroup>
            <FormGroup className="mb-3">
                <Form.Label>Nombre:</Form.Label>
                <Form.Control 
                            required 
                            name="name" 
                            onChange={ e=>onChange(e) } 
                            type="text" 
                            placeholder="Nombre"
                            defaultValue="" />

                <Form.Label className="mt-1">Apellidos:</Form.Label>
                <Form.Control 
                            required 
                            name="surnames" 
                            onChange={ e=>onChange(e) } 
                            type="text" 
                            placeholder="Apellidos"
                            defaultValue="" />
            </FormGroup>

            <RequestResult onClose={closeNotification} result={result}></RequestResult>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="primary" type="submit">Crear</Button>
        </Modal.Footer>

    </Form>    
    </Modal>
)

}

export default PatientCreateDialog
