import Cookies from "js-cookie";
import axios from "axios";
import { requestToServer,Action } from "./tools";

import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    ReqStatus
} from './types'

export const logout = () => async dispatch => {

    const config = {
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }
    try
    {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/logout/`,'',config)
        if (res.status === 200)
        {
            dispatch({
                type:LOGOUT_SUCCESS,                
            })
        }
        else
        {
            dispatch({
                type:LOGOUT_FAIL
            })
        }
    }
    catch(err)
    {
        dispatch({
            type:LOGOUT_FAIL
        })
    }
   
};


export const login = (email, password) => async dispatch => {
 
    const ret = await requestToServer(`login/`,Action.POST,JSON.stringify({email,password}))
    
    if (ret.code === ReqStatus.OK)
    {
        dispatch({
            type:LOGIN_SUCCESS,
            payload: ret.data                
        })
    }
    else
    {
        dispatch({
            type:LOGIN_FAIL
        })  
    }
    return ret
};



export const resetPassword = async function(email)
{
    return await requestToServer(`staff/resetpassword/`,Action.POST,JSON.stringify({"email":email}))
};

export const changePassword = async function(password)
{
    return await requestToServer(`staff/changepassword/`,Action.POST,JSON.stringify({"password":password}))
};

