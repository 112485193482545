import React from "react";
import { Tooltip,OverlayTrigger } from "react-bootstrap";

const Operator = ({operator, addClass="h5 text-secondary"}) =>
{

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <div>
                Medida tomada por: 
            </div>
            <div>
                {operator.first_name + " " + operator.last_names}
            </div>
            <div>
                ({operator.email})
            </div>
        </Tooltip>
      );


    return (
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
            <i className={"bi bi-file-person " + addClass}></i>
            </OverlayTrigger>          
      )

}

export default Operator
    