import axios from "axios";
import { codeToStatus } from "./tools";
import { ReqStatus } from "./types";


export const getResidencias = async function()
{
 
    const config = {
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',            
        }
    }

    try
    {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/residencia/group/`,config)
        if (res.status === 200)
        {
            return res.data
        }
        else
        {
            return null
        }
    }
    catch(err)
    {
        return null
    }
};


export const getGraph = async function(id)
{ 
    const config = {
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',            
        }
    }
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/measure/${id}/`,config)
    return data
};

export const getPatientMeasures = async function(id)
{
    const config = {
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',            
        }
    }    
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/patient/${id}/measure/`,config)
    return data
}

export const getMyResidencias = async function()
{
    const config = {
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',            
        }
    }    
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/residencia/`,config)
    return data
}


export const getlastMeasures = async function(id)
{
    const config = {
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',            
        }
    }
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/measure/${id}/last/`,config)            
    return data
};



export const getPatients = async function()
{
    const config = {
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',            
        }
    }
    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/measure/last/`,config)            
    return data
};


const requestToServer = async function(url)
{
    const config = {
        headers: {
            'Accept':'application/json',
            'Content-Type':'application/json',            
        }
    }
    try
    {
        const res = await axios.get(url,config)
        const status = codeToStatus(res.status)
        if (res.status === 200)
        {
            return { code: status,
                     data: res.data }

        }
        else
        {
            return { code: status,
                     data: null }            
        }
    }
    catch(err)
    {
        return { code: ReqStatus.ERROR,
                 data: null }
    }
 
}

export const geResidenciasSimple = async function()
{
    return await requestToServer(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/residencia/group/?rep=mini`)
};

export const getUsersSimple = async function()
{
    return await requestToServer(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/staff/`)
};

export const getUserResidencias = async function(id)
{
    return await requestToServer(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/staff/${id}/residencia/`)
};

export const getResidenciaUsers = async function(id)
{
    return await requestToServer(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/residencia/${id}/staff/`)
};

export const getStaff = async function()
{ 
    return await requestToServer(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/staff/`)
};

export const getPatientOfResidencia = async function(id)
{
    return await requestToServer(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_PREFIX}/adm/residencia/${id}/patient/`)
};
