import React from 'react';
import { useParams } from 'react-router-dom';
import { getGraph } from '../actions/getters';
import { Container } from 'react-bootstrap';

import Spo2Graph from '../components/Spo2Graph';
import ECGGraph from '../components/ECGGraph';
import PatientBox from '../components/PatientBox';
import Loading from '../components/Loading';
import Error from '../components/Error';
import Error2 from '../components/Error2';

import GoBack from '../components/GoBack';
import { useQuery } from "@tanstack/react-query";

const Graphs = () =>
{ 
    let { id } = useParams();

    const { isLoading, isError, data, error } = useQuery({ queryKey: ['graph',id],
                                                    queryFn: () => getGraph(id) })
    
    const graph = () =>
    {
        if( data.type === "SPO2" )
        {
            return <Spo2Graph curve_data={data.data} operator={data.user}></Spo2Graph>
        }
        else if(data.type === "ECG")
        {
            return <ECGGraph curve_data={data.data} operator={data.user}></ECGGraph>
        }
        else
        {
           return  <Error> Medida no váida </Error>
        }
    }
    
    if (isLoading)
    {        
      return( <Loading/> )
    }
    else if(isError)
    {
      return <Error2  error={error.response} 
                      messages = { [ [404, "Medida no encontrada"],
                                     [403, "No se puede acceder a la medida"] ] } />
    }
    else
    {
        
        return(

        <Container className='mt-4'>  
                 
            <GoBack></GoBack>
            <PatientBox patient={data.patient} link_to_record={true}></PatientBox>              
            <div className='my-3'>  
              {graph()}
            </div>

        </Container>

        )
    }
}

export default Graphs
