import React from "react";
import { Tooltip,OverlayTrigger } from "react-bootstrap";

const SimpleImageButton = ({image,tooltip,onClick,addClass="p-2 h5 text-secondary"}) =>
{
      const iconStyle = {
        cursor: 'pointer'
      };

      const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {tooltip}
        </Tooltip>
      );

      const fimage = () =>
      {
         return 'bi bi-'+ image
      }

      const executeOnClick = (e) =>
      {
        e.preventDefault();
        onClick()
      }

      return (

        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
        <i 
            onClick={executeOnClick} 
            style = {iconStyle}
            className={fimage() + " " + addClass} ></i>        
        </OverlayTrigger>          
      )

}

export default SimpleImageButton