import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getPatientMeasures } from '../actions/getters';
import { Col, Container,Row,Table, Alert } from 'react-bootstrap';
import moment from 'moment';

import PatientBox from '../components/PatientBox';
import Loading from '../components/Loading';
import Error2 from '../components/Error2';
import ComplexValueField from '../components/ComplexValueField';
import ViewGraphButton from '../components/ViewGraphButton';

import GoBack from '../components/GoBack';
import Reload from '../components/Reload';
import { useQuery } from "@tanstack/react-query";
import Operator from '../components/Operator';

import { useReactTable, 
         flexRender,
         getCoreRowModel,
         getPaginationRowModel, 
         getFilteredRowModel } from '@tanstack/react-table'

import Paginator from '../components/Paginator';         
import usePaginator from '../components/usePaginator';

import "../css/PatientRecord.css"

const ECGData = ({measure}) =>
{
    return (
        <Container fluid className='bg-white'>
            <Row>
                <Col xs={3}>
                    <ComplexValueField name={"Ritmo cardiaco"} value={measure.simplified_data.freq} units={"lpm"}></ComplexValueField>             
                </Col>
                <Col xs={3}></Col>
                <Col></Col>
                <Col xs={1} className="d-flex align-items-center">
                    <ViewGraphButton id={measure.id}></ViewGraphButton>
                </Col>
            </Row>
        </Container>        
    )
}

const SpO2Data = ({measure}) =>
{
    return (
        <Container fluid className='bg-white'>
            <Row>
                <Col xs={3}>
                     <ComplexValueField name={"Saturación"} value={measure.simplified_data.sat} units={"%"}></ComplexValueField> 
                </Col>
                <Col xs={3}>
                     <ComplexValueField name={"Pulso"} value={measure.simplified_data.pulse} units={"ppm"}></ComplexValueField> 
                </Col>
                <Col></Col>
                <Col xs={1} className="d-flex align-items-center">
                    <ViewGraphButton id={measure.id}></ViewGraphButton>
                </Col>
            </Row>
        </Container>
    )
}

const PNIData = ({measure}) =>
{
    return (
        <Container fluid className='bg-white'>
            <Row>
                <Col xs={3}>
                    <div className="ms-2 me-auto">
                       <div> <span className="fw-bold">Presión</span> <span className="text-muted">(mmHg)</span></div>
                       {measure.simplified_data.sys}/{measure.simplified_data.dias}    
                    </div>
                </Col> 
                <Col xs={3}>
                    <div className="ms-2 me-auto">
                        <div> <span className="fw-bold">Pulso</span> <span className="text-muted">(ppm)</span></div>
                        {measure.simplified_data.pulse}
                    </div>
                </Col>                
                <Col></Col>
                <Col xs={1}></Col>
            </Row>
        </Container>
    )
}

const MeasureTable = ({data}) =>
{
    const getDate = (str) => {
        return moment(str).format("DD/MM/YYYY")
    }

    const getTime = (str) => {
        return moment(str).format("HH:mm:ss")
    }

    const measr = (measure) =>
    {
        if(measure.type === "ECG")
        {
            return <ECGData measure={measure}></ECGData>
        }
        else if (measure.type === "SPO2")
        {
            return <SpO2Data measure={measure}></SpO2Data>
        }
        else if(measure.type === "PNI")
        {
            return  <PNIData measure={measure}></PNIData>
        }
    }

    const columns = useMemo( () => [
        {
            id : "col1",
            accessorKey: "date_time",
            header : "Fecha",
            cell: (props)=> <span> {getDate(props.getValue())} </span>
        },
        {
            id : "col2",
            accessorKey: "date_time",
            header : "Hora",
            cell: (props)=> <span> {getTime(props.getValue())} </span>
        },
        {
            id : "col3",
            accessorKey: "type",
            header : "Tipo",            
            cell: (props)=> <span> {props.getValue()} </span>     
        },
        {
            id : "col4",
            accessorFn: row => row,
            header : "Datos",          
            cell: (props)=> measr(props.getValue())
        },
        {
            id : "col5",
            accessorKey: "user",
            header : "",
            cell: (props)=>(<Operator operator={props.getValue()}></Operator>),            
        }
    ] , [] ) 
    


    const {initialIdx,
           initialSize,
           defaultSize,
           allowedSizes} = usePaginator(10,[10,20,30,40,50])

    const table = useReactTable({data: data.measures,
                                 columns,
                                 initialState: { 
                                    pagination: {
                                        pageSize:  initialSize,
                                        pageIndex: initialIdx
                                    }
                                },
                                getCoreRowModel:getCoreRowModel(),
                                getPaginationRowModel: getPaginationRowModel(),
                                getFilteredRowModel: getFilteredRowModel()})


    if(data.measures.length > 0)
    {

        return  ( 
                    <div>
                    <Table className="table-light small" striped hover> 
                    <thead>
                        {table.getHeaderGroups().map((group)=>(
                            <tr key={group.id}>
                                {group.headers.map((header)=>(
                                    <th key={header.id}>
                                        {header.column.columnDef.header}
                                    </th>
                                ))}
                            </tr>                            
                        ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map((cell)=>(                                    
                                <td key={cell.id} className={`cl_${cell.id.split('_').at(-1)}`}> 
                                    { flexRender(cell.column.columnDef.cell,
                                                 cell.getContext()) } 
                                </td>
                            ))}
                        </tr>
                        ))}                        
                    </tbody>
                 </Table>
                 <div>
                 </div>
                    <Paginator 
                            table={table}
                            defaultSize={defaultSize} 
                            allowedSizes={allowedSizes}/>
                 </div>)
    }
    else
    {
        return ( <Container className='mt-5'>
        <Alert className="text-center" variant={"warning"}>
          <h4>El paciente no tiene medidas</h4>
        </Alert>
     </Container>    )       

    }
    
}

const PatientRecord = () => 
{
    let { id } = useParams();

    const [val,setVal]         = useState(false)    
    const { isLoading, isError, data, error } = useQuery({ queryKey: ['patientrecord',id],
                                                           queryFn: () => getPatientMeasures(id) })

    const reRender = () => { 
        setVal(!val);
    }; 
                                                    
    if (isLoading)
    {  
        return( <Loading/> )               
    }
    else if (isError)
    {
        return <Error2  error={error.response} 
                        messages = { [ [404, "Residente no encontrado"],
                                       [403, "No se puede acceder al residente"] ] } />
    }    
    else
    {
        return(

        <Container>      
            <div className='mt-4'>      
                <div style={{height:'40px'}} className="position-relative">
                    <div className="position-absolute top-50 start-0 translate-middle-y">
                        <GoBack />
                    </div>
                    <div className="position-absolute top-50 end-0 translate-middle-y">                
                        <Reload refresh={reRender} 
                                queryKey={['patientrecord',id]}/>
                    </div>
                </div>
                <div>
                    <PatientBox patient={data}></PatientBox>  
                </div>
            </div>

            <div className='my-3'>  
                <MeasureTable data={data}></MeasureTable>
            </div>

        </Container>

        )
    }
}

export default PatientRecord