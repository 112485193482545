import React, {useRef, useState} from "react";
import { connect } from "react-redux";
import CSRFToken from '../components/CSRFToken'
import { changePassword } from "../actions/auth";
import { Button, Form, Container, FormGroup,Row,Col } from "react-bootstrap";
import GoBack from "../components/GoBack";
import { ReqStatus } from "../actions/types";
import WinNotification from "../components/WinNotification";

const Profile = ({authUser}) => {
    
    const [notification,setNotification]                   = useState(null)
    const [changeButtonDisabled,setChangeButtonDisabled]   = useState(true)
    const [password,setPassword]                           = useState({password:''});

    const pss1Ref = useRef()
    const pss2Ref = useRef()

    const onChange = e => {
        if (pss1Ref.current.value !== "" &&
            pss1Ref.current.value === pss2Ref.current.value)
        {
            setPassword( pss1Ref.current.value )
            setChangeButtonDisabled(false)
        }
        else
        {
            setPassword( "" )
            setChangeButtonDisabled(true)
        }        
    }
    
    const onSubmit = e =>{
        e.preventDefault();
        const fecthRequest = async() => {    
            try
            { 
              const dt = await changePassword(password)                              
              if ( dt.code === ReqStatus.OK )
              {
                setNotification({variant:"success",
                                 message:"La contraseña ha sido cambiada correctamente"})
                pss1Ref.current.value = ""
                pss2Ref.current.value = ""
              }
              else
              {
                setNotification({variant:"danger",
                                 message:"Error cambiando la contraseña"})
              }
            }
            catch (e)
            {
                setNotification({variant:"danger",
                                 message:"Error cambiando la contraseña"})
            }
          }
          fecthRequest();                            
    }

    const onCloseNotification = () =>
    {
        setNotification(null)
    }
    
    return (
        <Container className="mt-5">
        <WinNotification notification={notification} timeout={5000} onClose={onCloseNotification}></WinNotification> 
        
        <GoBack></GoBack>
        <div className="pt-3 pb-3 bg-light"> 
              <Row>
                <Col>
                    <div className="ml-2">
                        <h3> {authUser.first_name} {authUser.last_names}</h3>  
                        <span className="text-muted"> {authUser.email} </span>                             
                    </div>
                </Col>
                <Col xs={1}></Col>              
              </Row>
        </div>        

        <div className="mt-5">
            <h4 >Cambiar contraseña</h4>
            <Form onSubmit={e=>onSubmit(e)}>
                <CSRFToken />
                <FormGroup className="mt-3 mb-2">
                    <Form.Control required={true} ref={pss1Ref} className="mb-2" name="pss1" onChange={ e=>onChange(e) } type="password" placeholder="Nueva contraseña"/>
                    <Form.Control required={true} ref={pss2Ref} className="mb-2" name="pss2" onChange={ e=>onChange(e) } type="password" placeholder="Repetir contraseña"/>
                </FormGroup>            
                <Button variant="primary" disabled={changeButtonDisabled} type="submit">
                    Cambiar contraseña
                </Button>
            </Form>
        </div>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth
    }}; 

export default connect(mapStateToProps)(Profile)