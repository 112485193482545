import React ,{useState,useEffect}  from 'react';

import {  LineChart,
          Line,
          XAxis,
          YAxis,
          CartesianGrid,
          Label,
          Legend,
          Brush,
          Tooltip,
          ReferenceArea,
          ResponsiveContainer,
        } from 'recharts';  

import moment from 'moment';        
import Operator from './Operator';

const Spo2Graph = ({curve_data,operator}) =>
{
    const graph_color       = "#1aab40"
    const point_size        = 5
    const max_number_x_gaps = 35
    const brush_gap         = 20

    const [datasets,setDataSets]  = useState(null)
    const [interval, setInterval] = useState(19);
    const [window,   setWindow]   = useState({startIdx:0,endIdx:600});

    const createDataSets = (dta) => {

        if (dta === undefined || dta === null || Object.keys(dta).length === 0 ) return null
        
        let data_sat   = []
        let data_pulse = []
        let data_spo2  = []

        let max_x = 0;

        let x = 0
        for (let i = 0; i < dta.curve.length; i ++)
        {
            let y = null
            if (dta.curve[i] !== 255)
            {
                y = dta.curve[i]
            }
            data_spo2.push( { x: x,
                              y: y} );
            x = x + 15            
        }

        max_x = x;

        for (let i = 0; i < dta.sat.length; i ++)
        {
            if(dta.sat[i][1] > 0)
            {
                data_sat.push( { x: dta.sat[i][1] ,
                                 y: dta.sat[i][0] } );

                if(i === dta.sat.length - 1 && dta.sat[i][1]  > max_x)
                {
                    max_x = dta.sat[i][1]
                }
            }                            
        }

        let min_freq_y = 32767;
        let max_freq_y = 0;

        for (let i = 0; i < dta.pulse.length; i ++)
        {            
            if(dta.pulse[i][1] > 0)
            {
                data_pulse.push( { x: dta.pulse[i][1],
                                   y: dta.pulse[i][0] } );

                if (dta.pulse[i][0] < min_freq_y)
                {
                    min_freq_y = dta.pulse[i][0]
                }
                if (dta.pulse[i][0] > max_freq_y)
                {
                    max_freq_y = dta.pulse[i][0]
                }
                    
                if(i === dta.sat.length - 1 && dta.sat[i][1] > max_x)
                {
                    max_x = dta.sat[i][1]
                }    
            }                                                      
        }

        let domain_freq_min_y = 40
        let domain_freq_max_y = 120

        if(max_freq_y > domain_freq_max_y)
        {
            const aux  = max_freq_y - domain_freq_max_y
            const mult = Math.ceil(aux / 20.0)
            domain_freq_max_y = 20*mult + domain_freq_max_y
        }

        if(min_freq_y < domain_freq_min_y)
        {
            const aux  = domain_freq_min_y - min_freq_y
            const mult = Math.ceil(aux / 20.0)
            domain_freq_min_y = domain_freq_min_y - 20*mult
            if(domain_freq_min_y < 0)
            {
                domain_freq_min_y = 0
            }
        }

        const range_freq_y =  [ domain_freq_min_y , domain_freq_max_y ]


        let data = { sat:   data_sat,
                     pulse: data_pulse,
                     spo2:  data_spo2,
                     range_freq_y: range_freq_y,
                     duration: Math.ceil(max_x/1000.0)*1000 }
        return data;
    }

    useEffect(() => {
      const setData = async() => {    
          const ds = createDataSets(curve_data)
          setWindow({startIdx:0,endIdx:getInitialTickWidth(ds.spo2.length)})
          setDataSets(ds)                     
      }
      setData()        
    },[curve_data])


    const getInitialTickWidth = (length) =>
    {
        if (length > 400)
        {
            return 400
        }
        else
        {
            return length - 1
        }
    }


    const labelFormatter = (value, name) => 
    {
       return ""
    }

    const tickFormatter = (value,tick) => 
    {
        return (value/1000)
    }

    const tickBFormatter = (value,tick) => 
    {
        return (value*15/1000).toString() + "s"
    }


    const getSessionDuration = () =>
    {
        return Math.round( datasets.duration/1000.0 )
    }

    const getIntervalFrom_ms = (ms) =>
    {
        return Math.round(ms / 15.0) - 1
    }

    const calculateInterval_ms = (shown_ms) => 
    {
        if(shown_ms <= max_number_x_gaps*300)
        {
             return 300;
        }
        else
        {
            return Math.ceil( (shown_ms / max_number_x_gaps) / 300.0) * 300
        }               
    }



    const changeBrush = (a) =>
    {
        const ms     = (a.endIndex - a.startIndex)*15
        const ms_int = calculateInterval_ms(ms)
        const intr   = getIntervalFrom_ms(ms_int)
        setWindow({startIdx:a.startIndex,endIdx:a.endIndex})
        if(intr !== interval)
        {
            setInterval(intr)
        }
    }

    if ( datasets === null )
    {
        return <h4>No hay gráficos disponibles de SpO<sub>2</sub></h4>
    }
    
    return (

        <div style={{ width: '100%' }}>

        <div className='text-center'>
          
          <h4><span className='me-2'>Sesión SpO<sub>2</sub></span><Operator operator={operator}></Operator></h4>
          <div>             
             <span className='text-muted'>Fecha y hora:</span> {moment(curve_data.curve_ts).format("DD/MM/YYYY HH:mm:ss")}                                            
             <span style={ {marginLeft:5 + "em"} } className='text-muted'>Duración:</span> {getSessionDuration()} seg. 
          </div>          

        </div>
        { datasets.sat.length > 0 &&

          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              width={500}
              height={150}
              data={datasets.sat}
              syncId="satID"
              syncMethod="value"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >

            <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                  xAxisId="x_sat"                  
                  dataKey="x"                
                  style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}              
                  type="number"
                  tickFormatter={tickFormatter}
                  domain={[0,datasets.duration]}  
              >
                  <Label
                      position='insideBottom'                
                      value= {"segundos"}
                      style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}
                  />
              </XAxis>

              <YAxis type="number" tickCount={4} domain={[85, 100]} />
              <Legend verticalAlign="top" height={36}/>
              <Tooltip labelFormatter={labelFormatter}/>
              <ReferenceArea xAxisId="x_sat" x1={window.startIdx*15} x2={window.endIdx*15} y1={85} y2={100} strokeOpacity={0.1} />
              <Line 
                
                xAxisId="x_sat" 
                name="Saturación"  
                isAnimationActive={false} 
                type="stepAfter" 
                dot={{strokeWidth: point_size}} 
                unit="%"
                dataKey="y" 
                strokeWidth={0} 
                stroke="#8884d8" 
                fill="#8884d8"/>
                            
            </LineChart>
          </ResponsiveContainer>

        }

        { datasets.pulse.length > 0 &&

          <ResponsiveContainer width="100%" height={200}>
          <LineChart
              width={500}
              height={150}
              data={datasets.pulse}
              syncId="satID"
              syncMethod="value"
              margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
              }}
          >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                  xAxisId="x_freq"                  
                  dataKey="x"                
                  style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}              
                  type="number"
                  tickFormatter={tickFormatter}
                  domain={[0,datasets.duration]}  
              >
                  <Label
                      position='insideBottom'                
                      value= {"segundos"}
                      style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}
                  />
              </XAxis>

              <YAxis  domain={datasets.range_freq_y}  />
              
              <Legend  verticalAlign="top" height={36}/>
              <Tooltip labelFormatter={labelFormatter}/>

              <ReferenceArea xAxisId="x_freq" x1={window.startIdx*15} x2={window.endIdx*15} y1={datasets.range_freq_y[0]} y2={datasets.range_freq_y[1]} strokeOpacity={0.1} />

              <Line 
                  xAxisId="x_freq" 
                  name="Ritmo cardiaco"  
                  isAnimationActive={false} 
                  type="stepAfter" 
                  dot={{strokeWidth: point_size}} 
                  unit="ppm"
                  dataKey="y" 
                  strokeWidth={0} 
                  stroke={graph_color} 
                  fill={graph_color}/>
          </LineChart>
          </ResponsiveContainer>

        }

        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            width={500}
            height={200}
            data={datasets.spo2}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 15,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    xAxisId="x_spo2"                  
                    dataKey="x"
                    interval={interval}
                    tickFormatter={tickFormatter}
                    style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}                
                >
                    <Label
                        position='insideBottom'                
                        value= {"segundos"}
                        style={{ fontSize: '70%', fill: 'gray', padding: '100px' }}
                    />
                </XAxis>


            <YAxis axisLine={true} tickLine={false} tick={false} type="number" domain={[0,250]}/>
            <Legend verticalAlign="top" height={36}/>

            <Brush 
              tickFormatter={tickBFormatter}
              gap={brush_gap}
              startIndex={window.startIdx} 
              endIndex={window.endIdx}
              onChange={(a)=>{changeBrush(a)}}
            />

            <Line 
              xAxisId="x_spo2" 
              name="Curva pletismográfica"  
              isAnimationActive={false} 
              type="monotone" 
              dot={false} 
              dataKey="y" 
              strokeWidth={2} 
              stroke="#82ca9d"
              fill  ="#82ca9d"/>            
          </LineChart>
        </ResponsiveContainer>
      </div>

    )    
}

export default Spo2Graph

